import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  User, UserRole
} from '@app/shared';
import {Observable} from "rxjs";
import { environment } from '@env/environment';
import { LanguageEnum } from '@shared/constants';
import { TeacherStatus } from '../../components/schedule/dto/teacher.dto';

export default class UserDto {
  id: string;

  email: string;

  role: UserRole;

  teacherId?: string | null;

  teacherStatus?: TeacherStatus | null;

  clientId?: string | null;

  language: LanguageEnum;

  token?: string;

  firstName: string | null;

  lastName: string | null;

  gender: string | null;

  avatarLink: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * Inject dependencies
   */
  public constructor(private http: HttpClient) {}

  public login({ email, password }: { email: string; password: string }) {
    return this.http.post<User & { token: string }>('/user/log-in', {
      email,
      password,
    });
  }

  public pifagorApiLogin(email: string, password: string): Observable<UserDto> {
    return this.http.post<UserDto>(environment.PifagorApiAddress + '/auth/login',  {
      email,
      password,
    });
  }
}
