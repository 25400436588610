import { Component, inject, Injectable, Signal, signal } from '@angular/core';
import { AuthService } from '@modules/components/auth/auth.service';
import { catchError, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import LessonDto from '@modules/components/schedule/dto/lesson.dto';
import ScheduleApiService from '@modules/components/schedule/schedule-api.service';
import {
  ConductLessonStepNames
} from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/const/conduct-lesson-step.const';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { MathemaModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConductLessonFormService {
  private readonly authService = inject(AuthService);
  private readonly scheduleApiService = inject(ScheduleApiService);
  private readonly modalService = inject(NzModalService);
  private readonly translateService = inject(TranslateService);

  public fileList: NzUploadFile[] = [];
  public filesIdsToRemove: number[] = [];
  public imagePreview: string | undefined = '';
  public isImagePreviewVisible = false;

  private _isLoading = signal(false);
  public set isLoading(value: boolean) {
    this._isLoading.set(value);
  }
  public get isLoading(): Signal<boolean> {
    return this._isLoading;
  }

  private _isEdit = signal(false);
  public set isEdit(val: boolean) {
    this._isEdit.set(val);
  };
  public get isEdit(): Signal<boolean> {
    return this._isEdit;
  }

  private _isDisabledSubmit = signal(false);
  public set isDisabledSubmit(value: boolean) {
    this._isDisabledSubmit.set(value);
  }
  public get isDisabledSubmit(): Signal<boolean> {
    return this._isDisabledSubmit;
  }

  private _isChangedRates = signal(false);
  public set isChangedRates(value: boolean) {
    this._isChangedRates.set(value);
  }
  public get isChangedRates(): Signal<boolean> {
    return this._isChangedRates;
  }

  private _isChangedHomeworkCompletion = signal(false);
  public set isChangedHomeworkCompletion(value: boolean) {
    this._isChangedHomeworkCompletion.set(value);
  }
  public get isChangedHomeworkCompletion(): Signal<boolean> {
    return this._isChangedHomeworkCompletion;
  }

  private _isHumanNote = signal(false);
  public set isHumanNote(value: boolean) {
    this._isHumanNote.set(value);
  }
  public get isHumanNote(): Signal<boolean> {
    return this._isHumanNote
  }

  private _isNoteGenerated = signal(false);
  public set isNoteGenerated(value: boolean) {
    this._isNoteGenerated.set(value);
  }
  public get isNoteGenerated(): Signal<boolean> {
    return this._isNoteGenerated
  }

  public lessonData$: Observable<LessonDto>;
  public loadingError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public lessonForm: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    homeworkCompletion: new UntypedFormControl(null, [Validators.required]),
    homework: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(2048),
    ]),
    teacherRates: new UntypedFormControl(null, Validators.required),
    notes: new UntypedFormControl('', Validators.maxLength(2048)),
    links: new UntypedFormArray([], Validators.maxLength(5)),
    approveAiComment: new UntypedFormControl(false),
  });

  public isSimplifiedValidation(): boolean {
    return this.authService.isAdmin() || this.authService.isManager();
  }

  public onSubmit(lessonId: string, isConductInsteadSkip: boolean): Observable<void> {
    const formData = new FormData();
    const filesToUpload = this.fileList.filter(
      (file) => !file.currentlyUploaded
    );

    if (filesToUpload.length) {
      for (const file of filesToUpload) {
        formData.append('files', file as any);
      }
    }

    const body = {
      ...this.lessonForm.value,
      filesIdsToRemove: this.filesIdsToRemove.filter(Number.isInteger),
      isConductInsteadSkip: isConductInsteadSkip,
    };

    if (!body.approveAiComment && !this.isHumanNote()) {
      return this.showConfirmAlert()
        .pipe(
          filter((res) => res),
          tap(() => body.notes = ''),
          switchMap(() => this.submit(formData, lessonId, body))
        );
    }

    return this.submit(formData, lessonId, body)
  }

  private submit(formData: FormData, lessonId: string, body: Record<string, any>): Observable<void> {
    formData.append('body', JSON.stringify(body));

    this.isLoading = true;

    const method = this.isEdit()
      ? this.scheduleApiService.editConductedLesson.bind(
        this.scheduleApiService
      )
      : this.scheduleApiService.conductRegularLesson.bind(
        this.scheduleApiService
      );

    return method(lessonId, formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.lessonForm.reset();
        })
      );
  }

  private showConfirmAlert(): Observable<boolean> {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzData: {
        params: {
          title: this.translateService.instant('calendar.conduct-lesson.not-approved-generated-note-title'),
          message: this.translateService.instant('calendar.conduct-lesson.not-approved-generated-note'),
          declineText: this.translateService.instant('main.btn.back'),
          acceptText: this.translateService.instant('main.btn.yes')
        }
      },
    });

    modalRef.componentInstance.onDecline.subscribe(() => modalRef.close(false));
    modalRef.componentInstance.onAccept.subscribe(() => modalRef.close(true));

    return modalRef.afterClose
  }

  public addFile(files: NzUploadFile[]): void {
    this.fileList.push(...files)
  }

  public getFiles(): NzUploadFile[] {
    return this.fileList;
  }

  public updateLessonForm(lesson: LessonDto): void {
    this.isHumanNote = !!lesson.notes?.length;
    this.lessonForm.patchValue({
      subject: lesson.subject,
      homework: lesson.homework,
      homeworkCompletion: lesson.homeworkCompletion,
      notes: lesson.notes,
      teacherRates: lesson.teacherRates,
    });

    if (lesson.links?.length) {
      for (const link of lesson.links) {
        this.links.push(new UntypedFormControl(link));
      }
    }

    this.addFile(
      lesson.materials.map((el) => ({
        id: el.id,
        uid: el.externalId,
        name: `${el.externalId}.${el.extension}`,
        url: el.src,
        currentlyUploaded: true,
      }))
    );
  }

  get links(): UntypedFormArray {
    return this.lessonForm.get('links') as UntypedFormArray;
  }

  public isInvalidStep(step: ConductLessonStepNames): boolean {
    if (this.authService.isManager() || this.authService.isAdmin()) return false;

    switch (step) {
      case ConductLessonStepNames.LESSON_INFO:
        return this.lessonForm.get('subject').invalid
            || this.lessonForm.get('homework').invalid
            || this.lessonForm.get('homeworkCompletion').invalid;

      case ConductLessonStepNames.NEED_ATTENTION:
        return this.lessonForm.get('teacherRates').invalid;

      case ConductLessonStepNames.ADDITIONAL_INFO:
        return this.lessonForm.invalid;

      default:
        return false;
    }
  }

  public isSubmitBtnDisabled(step: ConductLessonStepNames): boolean {
    const isInvalidStep = this.isInvalidStep(step);
    const isDisabledSubmit = this.isDisabledSubmit();

    return isDisabledSubmit || isInvalidStep;
  }

  public clearForm(): void {
    this.lessonForm.reset({
      subject: '',
      homework: '',
      teacherRates: null,
      notes: '',
    });
    this.links.clear();
    this.fileList = [];
    this.filesIdsToRemove = [];
    this.imagePreview = '';
    this.isImagePreviewVisible = false;
  }

  public initForm(lessonId: string, editMode: boolean): void {
    this.isEdit = editMode;
    this.clearForm();

    this.loadingError$.next(false);
    this.lessonData$ = this.scheduleApiService
      .getConductedLessonDetails(lessonId)
      .pipe(
        tap((lesson) => {
          this.updateLessonForm(lesson);
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        })
      );
    if (this.isSimplifiedValidation()) {
      this.lessonForm.controls.subject.clearValidators();
      this.lessonForm.controls.homework.clearValidators();
    }
  }
}
