import { Component, inject, OnInit } from '@angular/core';
import { ConductLessonFormService } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import { NzFormDirective } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { TranslatePipe } from '@ngx-translate/core';
import {
  NzCollapseComponent,
  NzCollapsePanelComponent,
} from 'ng-zorro-antd/collapse';
import { goodNotesData } from '../../const/conduct-lesson-rates-list.const';

@Component({
  selector: 'math-good-notes',
  standalone: true,
  imports: [
    NzFormDirective,
    ReactiveFormsModule,
    NzTagModule,
    NzIconDirective,
    TranslatePipe,
    NzCollapseComponent,
    NzCollapsePanelComponent,
  ],
  templateUrl: './good-notes.component.html',
  styleUrl: './good-notes.component.scss',
})
export class GoodNotesComponent implements OnInit {
  protected readonly conductLessonFormService = inject(
    ConductLessonFormService
  );

  protected readonly goodNotes: Record<string, any>[] = JSON.parse(
    JSON.stringify(goodNotesData)
  );

  public ngOnInit(): void {
    const positiveNotes =
      this.conductLessonFormService.lessonForm.get('teacherRates').value || [];
    this.goodNotes.forEach((goodNotes) => {
      goodNotes.rates.forEach((rate) => {
        if (positiveNotes.indexOf(rate.key) !== -1) {
          rate.value = true;
        }
      });
    });
  }

  public onRateDetailChange(key: string, value: boolean) {
    const positiveNotesControl =
      this.conductLessonFormService.lessonForm.get('teacherRates');
    const positiveNotes: string[] = positiveNotesControl.value || [];

    if (value) {
      positiveNotesControl.setValue([...positiveNotes, key]);
    } else {
      positiveNotesControl.setValue(positiveNotes.filter((el) => el !== key));
    }
  }
}
