import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppRoutesDefinitions } from '@app/app.routes';
import { NotificationService } from '@shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import PifagorApiService from '@shared/services/pifagor-api.service';
import { SignupInfo } from '@shared/models/signup-info';
import { LanguageEnum } from '@shared/constants';
import { UserRole } from '@app/shared';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  passwordMinLength = 8;
  teacherId: string;
  clientId: string;
  language: LanguageEnum;
  role: UserRole;
  getDataSub: Subscription;

  public user = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [Validators.required, Validators.minLength(this.passwordMinLength)],
    ],
    passwordConfirm: ['', [Validators.required, this.passwordConfirming()]],
  });

  public isSubmitted: boolean = false;
  passwordView = false;

  constructor(
    protected translateService: TranslateService,
    public fb: UntypedFormBuilder,
    public pifagorApiService: PifagorApiService,
    public route: ActivatedRoute,
    private readonly router: Router,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.teacherId) {
        this.role = UserRole.TEACHER;
        this.teacherId = params.teacherId;
        this.getTeacherSignupInfo(this.teacherId);
      }
      if (params.clientId) {
        this.role = UserRole.CLIENT;
        this.clientId = params.clientId;
        this.getClientSignupInfo(this.clientId);
      }
    });
  }

  getPassViewType(): string {
    return this.passwordView ? 'text' : 'password';
  }

  togglePassView(): void {
    this.passwordView = !this.passwordView;
  }

  passwordConfirming(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.user) {
        const valid = control.value === this.user.controls['password'].value;

        return !valid ? { passwordMismatch: true } : null;
      } else return null;
    };
  }

  getTeacherSignupInfo(id: string): void {
    this.getDataSub = this.pifagorApiService
      .getTeacherInfoForSignup(id)
      .subscribe(
        (data) => {
          this.updateSignupInfo(data);
        },
        (err) => console.log(err)
      );
  }

  getClientSignupInfo(id: string): void {
    this.getDataSub = this.pifagorApiService
      .getClientInfoForSignup(id)
      .subscribe(
        (data) => {
          this.updateSignupInfo(data);
        },
        (err) => console.log(err)
      );
  }

  private updateSignupInfo(data: SignupInfo): void {
    if (data) {
      this.language = data.language;
      this.switchLanguage(this.language);
      this.user.patchValue({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      });
      this.user.updateValueAndValidity();
      this.user.controls['email'].disable();
    }
  }

  private switchLanguage(lang: LanguageEnum): void {
    this.translateService.use(String(lang).toLowerCase());
  }

  register(): void {
    this.isSubmitted = true;
    if (!this.teacherId && !this.clientId) {
      this.notificationService.showErrorMessage(
        this.translateService.instant('registration_rejected_unverified')
      );
      return;
    }
    if (this.user.valid) {
      const userModel = {
        firstName: this.user.controls['firstName'].value,
        lastName: this.user.controls['lastName'].value,
        email: this.user.controls['email'].value,
        password: this.user.controls['password'].value,
        teacherId: this.teacherId,
        clientId: this.clientId,
        role: this.role,
        language: this.language,
      };
      this.pifagorApiService.registerUser(userModel).subscribe((_) => {
        this.router.navigate([AppRoutesDefinitions.SIGNUP_SUCCESS]);
      });
    }
  }

  ngOnDestroy() {
    if (this.getDataSub) this.getDataSub.unsubscribe();
  }
}
