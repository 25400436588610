import UserDto from '@modules/api/services';

export enum NotificationType {
  SKIP_LESSON = 'skip_lesson',
  MOVE_LESSON = 'move_lesson',
  PROFILE_CHANGE = 'profile_change',
  CONDUCT_INSTEAD_SKIP = 'conduct_instead_skip',
  CREATED_REPORT = 'created_report',
  NEED_REPORT = 'need_report',
  SCHEDULE_CHANGED = 'schedule_changed',
  FIRST_LESSON = 'first_lesson',
  SYSTEM_NOTIFICATION = 'system_notification',
}

export interface NotificationMessage {
  userId: string,
  notificationId: string,
  readAt: Date,
  notification: {
    id: string,
    type: NotificationType,
    createdAt: Date,
    message: string,
    author?: UserDto
  }
}
