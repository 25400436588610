<form nz-form
      [formGroup]="conductLessonFormService.lessonForm"
      nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzFor="notes">
      {{ 'calendar.conduct-lesson.notes' | translate }}
    </nz-form-label>

    <nz-form-control>
      @if (notesLoader()) {
        <math-loader></math-loader>
      }
            <textarea
              [ngClass]="{ 'ai-highlight': conductLessonFormService.isNoteGenerated() }"
              rows="2"
              formControlName="notes"
              nz-input
              (focus)="isFocusedNotes = true"
              (focusout)="isFocusedNotes = false"
              [placeholder]="'calendar.conduct-lesson.notes' | translate"
              nzAutosize
              maxlength="2048"
            ></textarea>
    </nz-form-control>
    @if (conductLessonFormService.isNoteGenerated()) {
      <span class="ai-alert">
        <label nz-checkbox
               formControlName="approveAiComment">
          {{ 'calendar.conduct-lesson.notes-generated' | translate }}
        </label>
      </span>
    }
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="links">
      {{ 'calendar.conduct-lesson.links' | translate }}
    </nz-form-label>

    <nz-form-control class="links">
      @for (link of conductLessonFormService.links.controls; track link.value?.name; let index = $index) {
        <nz-tag
          nzMode="closeable"
          (nzOnClose)="onRemoveLink(index)"
          nz-tooltip
          [nzTooltipTitle]="link.value"
        >
          <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
        </nz-tag>
      }

      @if (conductLessonFormService.links.controls?.length < 5) {
        @if (!linkInputVisible) {
          <nz-tag class="editable-tag" nzNoAnimation (click)="showLinkInput()">
            <span nz-icon
                  nzType="plus"></span>
            {{ 'calendar.conduct-lesson.new-link' | translate }}
          </nz-tag>
        } @else {
          <input
            #linkInputElement
            nz-input
            nzSize="small"
            type="text"
            [(ngModel)]="linkInputValue"
            [ngModelOptions]="{standalone: true}"
            style="width: 80px;"
            (blur)="handleLinkInputConfirm()"
            (keydown.enter)="handleLinkInputConfirm()"
          />
        }
      }
    </nz-form-control>
  </nz-form-item>

  <div style="margin-bottom: 16px">
    <mathema-info-block [_type]="'info'"
                        [text]="'calendar.conduct-lesson.homework-materials-tip' | translate">
    </mathema-info-block>
  </div>

  <nz-form-item>
    @if (conductLessonFormService.getFiles().length) {
      <nz-form-label nzFor="materials">
        {{ 'calendar.conduct-lesson.materials' | translate }}
      </nz-form-label>
    }

    <nz-form-control>

      @if (conductLessonFormService.getFiles().length) {
        <div class="materials">
          <nz-upload
            [(nzFileList)]="conductLessonFormService.fileList"
            [nzShowButton]="false"
            nzListType="picture-card"
            [nzPreview]="handleImagePreview"
            [nzRemove]="handleImageRemove"
          >
          </nz-upload>
        </div>
      }

      <nz-upload
        [nzBeforeUpload]="beforeImageUpload"
        [nzPreview]="handleImagePreview"
        [nzShowButton]="conductLessonFormService.getFiles().length < 5"
        (nzChange)="handleUploadImageChange($event)"
        [nzMultiple]="true"
        [nzLimit]="5">
        <div class="upload-controls">
          <button nz-button
                  class="upload-button">
            {{ 'calendar.conduct-lesson.upload' | translate }}
          </button>

          <div class="upload-info">
            {{ 'calendar.conduct-lesson.upload-tip' | translate }}
          </div>
        </div>
      </nz-upload>

      <nz-modal
        [nzVisible]="conductLessonFormService.isImagePreviewVisible"
        [nzContent]="modalContent"
        [nzFooter]="null"
        (nzOnCancel)="conductLessonFormService.isImagePreviewVisible = false">
        <ng-template #modalContent>
          <img alt="" [src]="conductLessonFormService.imagePreview"
               [ngStyle]="{ width: '100%' }" />
        </ng-template>
      </nz-modal>
    </nz-form-control>
  </nz-form-item>
</form>
