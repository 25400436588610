@let item = message();

<li [ngClass]="{
            'is-new': !item.readAt,
            'no-border': last()
           }"
    class="notification-message">
      <span class="notification-type">
        <img [src]="notifTypeToIconMap[item.notification.type]" alt="" />
      </span>

  <div class="message">
    <div [innerHTML]="item.notification.message">
    </div>

    <div class="date">
      {{ item.notification.createdAt | date: 'dd.MM.YYYY HH:mm' }}
    </div>
  </div>
</li>
