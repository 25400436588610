<div class="conduct-lesson-modal">

  @if (conductLessonFormService.lessonData$ | async; as lesson) {
    <div class="wrapper">
      <div class="info-block">
        <div>
          <div class="title">
            {{ 'calendar.conduct-lesson.date-time' | translate }}
          </div>

          <div class="content date">
            {{ formatDate(lesson.date) }}

            <span class="time">
            {{ timeSlotToTime(lesson.timeSlot) }}
          </span>
          </div>
        </div>

        <div class="info-alert"
             [ngClass]="{'error': showHeaderError() }">
          <span nz-icon
                nzType="info-circle"
                nzTheme="fill">
          </span>

          @if (showHeaderError() && conductLessonFormService.isInvalidStep(ConductLessonStepNames.NEED_ATTENTION)) {
            {{ 'calendar.conduct-lesson.at-least-one-rate' | translate }}
          } @else if (showHeaderError() && conductLessonFormService.isInvalidStep(ConductLessonStepNames.LESSON_INFO)) {
            {{ 'main.form.errors.required-fields' | translate }}
          } @else {
            {{ 'calendar.conduct-lesson.need-fill-report' | translate }}
          }
        </div>
      </div>

      <math-customized-stepper
        [activeStep]="activeStep()"
        [steps]="formSteps"
        (stepSwitched)="onSwitchStep($event)">
      </math-customized-stepper>

      @switch (activeStep()) {
        @case (ConductLessonStepNames.LESSON_INFO) {
          <h4>
            <span nz-icon
                  nzType="file-text"
                  nzTheme="fill"></span>
            {{ 'calendar.conduct-lesson.lesson-info' | translate }}
          </h4>
          <math-lesson-info>
          </math-lesson-info>
        }
        @case (ConductLessonStepNames.GOOD_NOTES) {
          <h4>
            <span nz-icon
              nzType="like"
              nzTheme="fill"></span>
            {{ 'calendar.conduct-lesson.good-notes' | translate }}
          </h4>
          <math-good-notes>
          </math-good-notes>
        }
        @case (ConductLessonStepNames.NEED_ATTENTION) {
          <h4 class="need-attention">
            <span nz-icon
                  nzType="info-circle"
                  nzTheme="fill">
            </span>
            {{ 'calendar.conduct-lesson.need-attention' | translate }}
          </h4>
          <math-need-attention>
          </math-need-attention>
        }
        @case (ConductLessonStepNames.ADDITIONAL_INFO) {
          <h4>
            <span nz-icon
                  nzType="file-add"
                  nzTheme="fill">
            </span>
            {{ 'calendar.conduct-lesson.additional-info' | translate }}
          </h4>
          <math-additional-info [lessonId]="lessonId()">
          </math-additional-info>
        }
        @default {
          Report was successfully created
        }
      }
    </div>

    <div class="action-buttons">
      <button class="cancel"
              (click)="back()">
        @if (activeStep() === ConductLessonStepNames.LESSON_INFO) {
          {{ 'main.btn.cancel' | translate }}
        } @else {
          {{ 'main.btn.back' | translate }}
        }
      </button>

      <button class="accept"
              [disabled]="conductLessonFormService.isSubmitBtnDisabled(activeStep())"
              (click)="next()">
        @if (activeStep() !== ConductLessonStepNames.ADDITIONAL_INFO) {
          {{ 'main.btn.next' | translate }}
        } @else {
          {{ (conductLessonFormService.isEdit ? 'main.btn.save' : 'calendar.conduct-lesson.accept') | translate }}
        }
      </button>
    </div>
  } @else {
    <div class="error">
      @if (conductLessonFormService.loadingError$ | async) {
        <mathema-some-loading-error>
        </mathema-some-loading-error>
      }
    </div>
  }

  @if (conductLessonFormService.isLoading()) {
    <div class="conduct-lesson-loader">
      <nz-spin [nzIndicator]="indicatorTemplate"
               nzSimple>
      </nz-spin>

      <ng-template #indicatorTemplate>
        <span style="font-size: 36px; color: #613CB0;"
              nz-icon
              nzType="loading">
        </span>
      </ng-template>
    </div>
  }
</div>
