import { Injectable, signal, WritableSignal } from '@angular/core';
import { NotificationMessage } from '@shared/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsDataService {
  private readonly notificationsList: WritableSignal<NotificationMessage[]> = signal([]);
  public readonly isNewNotice: WritableSignal<boolean> = signal(false);

  private readonly alert = new Audio('assets/sounds/notification.wav');

  constructor() { }

  public init(notifications: NotificationMessage[]): void {
    Notification.requestPermission();
    const hasNew = !!notifications.find(({ readAt }) => !readAt);

    this.isNewNotice.set(hasNew);
    this.notificationsList.set(notifications);
  }

  public add(notification: NotificationMessage): void {
    this.alert.play().catch(error => {
      console.error('Playback failed:', error);
    });

    this.isNewNotice.set(true);
    this.notificationsList
      .update(list => [notification, ...list]);
  }

  public get(): WritableSignal<NotificationMessage[]> {
    return this.notificationsList;
  }
}
