import { Component, input, output } from '@angular/core';
import { NzStepComponent, NzStepsComponent } from 'ng-zorro-antd/steps';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { TranslatePipe } from '@ngx-translate/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { StepInterface } from '@shared/components/customized-stepper/step.interface';

@Component({
  selector: 'math-customized-stepper',
  standalone: true,
  imports: [
    NzStepComponent,
    NzStepsComponent,
    NzPopoverDirective,
    TranslatePipe,
    NgTemplateOutlet,
    NgClass,
  ],
  templateUrl: './customized-stepper.component.html',
  styleUrl: './customized-stepper.component.scss',
})
export class CustomizedStepperComponent {
  public allowNavigation = input<boolean>(true);
  public activeStep = input<number>(0);
  public steps = input<StepInterface[]>([]);
  public stepSwitched = output<number>();

  protected switchStep(i: number): void {
    if (!this.allowNavigation()) return;

    this.stepSwitched.emit(i);
  }
}
