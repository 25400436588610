export enum LessonTeacherRates {
  // Good Notes Data
  FAST_TO_LEARN = 'fast_to_learn',
  NO_ISSUES = 'no_issues',
  CORRECT_APPLIED_RULES = 'correct_applied_rules',
  SELF_RESOLVED_TASK = 'self_resolved_task',
  GOOD_EXPLAINED_SOLUTIONS = 'good_explained_solutions',
  FAST_ANSWERS = 'fast_answers',
  RESOLVE_EVERYTHING = 'resolve_everything',
  GOOD_ATTENTION = 'good_attention',
  NOTED_IMPORTANT_INFO = 'noted_important_info',
  QUICKLY_STARTED_WORK = 'quickly_started_work',
  PREPARED = 'prepared',
  KEPT_ATTENTION = 'kept_attention',
  RESOLVED_TASKS_IN_TIME = 'resolved_tasks_in_time',
  APPLIED_RECOMMENDATIONS = 'applied_recommendations',
  EFFICIENCY = 'efficiency',

  // Need Attention Data
  NEW_THEME_PROBLEM = 'new_theme_problem',
  BASE_RULES_ISSUES = 'base_rules_issues',
  SELF_TASK_FAILED = 'self_task_failed',
  CONFUSED_ABOUT_CONCEPTS = 'confused_about_concepts',
  ADDITIONAL_EXPLANATION_NEEDED = 'additional_explanation_needed',
  LAZY_STARTED_WORK = 'lazy_started_work',
  ONLY_EASY_QUESTIONS_SOLVED = 'only_easy_questions_solved',
  AVOIDED_TASKS = 'avoided_tasks',
  SLOW_TASKS_SOLUTION = 'slow_tasks_solution',
  NOT_COMPLETE_TASKS = 'not_complete_tasks',
  WAS_NOT_PREPARED = 'was_not_prepared',
  DISTRACTED_FROM_WORK = 'distracted_from_work',
  TECHNICAL_ISSUES = 'technical_issues',
  LONG_PREPARATIONS = 'long_preparations',
  IGNORED_COMMENTS = 'ignored_comments',
}
