<nz-layout class="calendar-page">
<!--  PAGE TITLE-->
  <ng-template #pageTitleTmp>
    @if (!authService.isTeacher()) {
      <h1>
        {{'calendar.timetable' | translate}}
        <span class="teacher-name">
              {{scheduleCalendarService.activeTeacher.value?.lastName}} {{scheduleCalendarService.activeTeacher.value?.firstName}}
            </span>
      </h1>
    } @else {
      @if (scheduleCalendarService.activeTeacher.value; as teacher) {
        <nz-badge
          [nzCount]="teacher.blockedAvailability ? blockedBadge : null"
          nz-tooltip
          [nzTooltipTrigger]="teacher.blockedAvailability ? 'hover' : null"
          [nzTooltipTitle]="getTeacherBlockedTooltipForTeacher(teacher.blockedAt)"
        >
          <h1>{{'calendar.timetable' | translate}}</h1>
        </nz-badge>

        <ng-template #blockedBadge>
        <span
          nz-icon
          nzType="exclamation-circle"
          class="ant-scroll-number-custom-component"
          style="color: #f5222d"></span>
        </ng-template>
      }
    }
  </ng-template>
<!--  END PAGE TITLE-->

<!--  ACTION BUTTONS-->
  <ng-template #mainActionButtonsTmp>
    @if (authService.isTeacher()) {
      <div class="free-slots-btn">
        <button class="action-btn bordered" (click)="onFreeSlotsClick()">
        <span nz-icon>
          <img src="./assets/icons/pencil.svg" alt="plus">
        </span>

          <span class="text-p-left">
          {{'calendar.filters.slots' | translate}}
        </span>
        </button>
      </div>
    }

    @if (authService.isManager() || authService.isAdmin()) {
      <div class="add-btn">
        <button class="action-btn bordered" nz-dropdown [nzDropdownMenu]="addMenu"
                [nzOverlayClassName]="'add-menu-overlay'" nzTrigger="click"
                (nzVisibleChange)="isAddMenuOpened = !isAddMenuOpened">
                <span nz-icon>
                  <img src="./assets/icons/plus.svg" alt="plus">
                </span>
          <span class="text">{{'main.btn.add' | translate}}</span>
          <span nz-icon [nzRotate]="isAddMenuOpened ? 0 : 180">
                  <img src="./assets/icons/suffix.svg" alt="suffix">
                </span>
        </button>
        <nz-dropdown-menu #addMenu="nzDropdownMenu">
          <ul nz-menu>
            <ng-container *ngIf="scheduleCalendarService.activeTeacher.value as teacher; else addMenuWithoutTeacher">
              <li
                nz-menu-item
                (click)="onOpenFirstLessonForm()"
                [nzDisabled]="!!teacher.notTakeNewStudentsAt || teacher.blockedAvailability"
                nz-tooltip
                [nzTooltipTrigger]="(teacher.notTakeNewStudentsAt || teacher.blockedAvailability) ? 'hover' : null"
                [nzTooltipTitle]="getTeacherBlockedTooltipForStuff(teacher.notTakeNewStudentsAt, teacher.blockedAvailability, teacher.blockedAt)"
              >
                <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
              </li>
              <li nz-menu-item (click)="onFreeSlotsClick()">
                <span>{{'calendar.filters.slots' | translate}}</span>
              </li>
            </ng-container>
            <ng-template #addMenuWithoutTeacher>
              <li nz-menu-item (click)="onOpenFirstLessonForm()">
                <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
              </li>
            </ng-template>
            <li nz-menu-item (click)="onOpenVacations()">
              <span>{{'calendar.vacations.open-btn' | translate}}</span>
            </li>
            <li nz-menu-item (click)="onNewDealClick()">
              <span>{{'lessons_package' | translate}}</span>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    }
  </ng-template>

  <ng-template #actionButtonsTmp>
    <div class="main-action-buttons">
      <ng-container [ngTemplateOutlet]="mainActionButtonsTmp"></ng-container>
    </div>
  </ng-template>
<!--  AND ACTION BUTTONS -->

  <math-base-wrapper
    [pageTitle]="pageTitleTmp"
    [actionButtons]="authService.isClient() ? null : actionButtonsTmp">
    <nz-layout class="layout-wrapper">
      <!-- Filters -->
      <nz-drawer
        *ngIf="tabletMode"
        [nzClosable]="false"
        (nzOnClose)="toggleFiltersDrawer(true)"
        [nzVisible]="authService.isClient() ? !isClientFiltersDrawerClosed : !isFiltersDrawerClosed"
        [nzContent]="authService.isClient() ? clientsFiltersComponent : filtersComponent"
        nzPlacement="left"
        [nzWidth]="280"
        [nzTitle]="filtersDrawerTitle"
        nzWrapClassName="schedule-filters-drawer"
      ></nz-drawer>
      <nz-sider
        *ngIf="!tabletMode"
        nzCollapsible
        nzBreakpoint="lg"
        [nzTrigger]="null"
        [nzCollapsedWidth]="0"
        (nzCollapsedChange)="toggleFiltersSidebar($event)"
        [nzCollapsed]="authService.isClient() ? isClientFiltersSidebarCollapsed : isFiltersSidebarCollapsed"
        [nzWidth]="280"
        class="sidebar"
      >
        <ng-template [ngTemplateOutlet]="filtersSidebarTitle"></ng-template>
        <ng-template [ngTemplateOutlet]="authService.isClient() ? clientsFiltersComponent : filtersComponent"></ng-template>
      </nz-sider>
      <!-- END Filters -->

      <!-- Lesson details -->
      @if(!isLessonDetailsDrawerClosed) {
        <nz-drawer
          [nzClosable]="false"
          (nzOnClose)="onCloseLessonDetails()"
          [nzVisible]="!isLessonDetailsDrawerClosed"
          [nzContent]="lessonDetailsComponent"
          nzPlacement="right"
          [nzWidth]="370"
          [nzTitle]="lessonDetailsDrawerTitle"
          nzWrapClassName="lesson-details-drawer"
        ></nz-drawer>
      }

      <!-- Content -->
      <nz-content class="content calendar-content">

        <nz-row [nzGutter]="15">
        <!--        FILTERS BTN-->
          @let showFiltersBtn = isFiltersSidebarCollapsed || isClientFiltersSidebarCollapsed || tabletMode;
          @if (showFiltersBtn) {
            <nz-col [nzSm]="4"
                    [nzMd]="3"
                    [nzXl]="2"
                    [nzXs]="0">
              <button
                (click)="tabletMode ? toggleFiltersDrawer(false) : toggleFiltersSidebar(false)"
                class="action-btn filters">
                {{'filters' | translate}}
              </button>
            </nz-col>
          }
          <!--        END FILTERS BTN-->

          <!--        CALENDAR ALERTS-->
          @if (authService.isTeacher() && authService.isUserUkrainian()) {
            <nz-col [nzSm]="showFiltersBtn ? 20 : 24"
                    [nzMd]="showFiltersBtn ? 21 : 24"
                    [nzXl]="showFiltersBtn ? 22 : 24"
                    [nzXs]="24">
              <div class="conduction-warning">
                {{'calendar.free-students-warning' | translate}}
              </div>
            </nz-col>
          }

          @if (authService.isTeacher() && !authService.isUserUkrainian() && isUncoductedLessonsPresent) {
            <nz-col [nzSm]="showFiltersBtn ? 20 : 24"
                    [nzMd]="showFiltersBtn ? 21 : 24"
                    [nzXl]="showFiltersBtn ? 22 : 24"
                    [nzXs]="24">
              <div class="conduction-warning">
                {{'calendar.conduction-warning' | translate}}
              </div>
            </nz-col>
          }
          <!--        END CALENDAR ALERTS-->
        </nz-row>

<!--        CALENDAR NAVIGATION-->
        <nz-row class="view-switchers">
          <nz-col>
            <mathema-schedule-calendar-type-switch [desktopMode]="true"></mathema-schedule-calendar-type-switch>
          </nz-col>
          <nz-col
            class="diapason-switch">
            <mathema-schedule-calendar-diapason-switch [desktopMode]="true"></mathema-schedule-calendar-diapason-switch>
          </nz-col>
          <nz-col
            class="today-btn">
            <button class="action-btn bordered" (click)="onTodayClick()">
              {{'today' | translate}}
            </button>
          </nz-col>
        </nz-row>
        <nz-row class="header-mobile">
          <div class="actions-block">
            <button
              class="action-btn bordered filters"
              (click)="toggleFiltersModal(false)"
            >
              {{'filters' | translate}}
            </button>

            <div class="main-action-buttons">
              <nz-col class="today-btn">
                <button class="action-btn bordered" (click)="onTodayClick()">
                  {{'today' | translate}}
                </button>
              </nz-col>
            </div>
          </div>
        </nz-row>
        <!--      END CALENDAR NAVIGATION-->

        <nz-row class="calendar">
          <section class="host"><ng-template scheduleCalendarHost></ng-template></section>
        </nz-row>

        <nz-row class="footer-mobile">
          <nz-col nzFlex="1">
            <mathema-schedule-calendar-type-switch>
            </mathema-schedule-calendar-type-switch>
          </nz-col>
          <nz-col nzFlex="3">
            <mathema-schedule-calendar-diapason-switch>
            </mathema-schedule-calendar-diapason-switch>
          </nz-col>
        </nz-row>
      </nz-content>

      <nz-drawer
        *ngIf="!mobileMode && !isFirstLessonDrawerCollapsed"
        [nzClosable]="false"
        (nzOnClose)="onCloseFirstLessonForm()"
        [nzVisible]="!isFirstLessonDrawerCollapsed"
        [nzContent]="firstLessonComponent"
        nzPlacement="right"
        [nzWidth]="365"
        [nzTitle]="null"
        nzWrapClassName="create-first-lesson-drawer"
      ></nz-drawer>

      <!-- Move lesson -->
      <nz-sider
        *ngIf="!tabletMode && !isMoveLessonSidebarCollapsed"
        nzBreakpoint="lg"
        [nzWidth]="365"
        class="move-lesson-sider sidebar right-sidebar"
      >
        <ng-template [ngTemplateOutlet]="moveLessonComponent"></ng-template>
      </nz-sider>
      <nz-drawer
        *ngIf="tabletMode"
        [nzClosable]="false"
        (nzOnClose)="onCloseMoveLesson()"
        [nzVisible]="!isMoveLessonDrawerCollapsed"
        [nzContent]="moveLessonComponent"
        nzPlacement="right"
        [nzWidth]="365"
        [nzTitle]="null"
        nzWrapClassName="move-lesson-drawer"
      ></nz-drawer>
    </nz-layout>

    <!-- Filters templates -->
    <ng-template #filtersComponent>
      <mathema-schedule-calendar-filters></mathema-schedule-calendar-filters>
    </ng-template>

    <ng-template #clientsFiltersComponent>
      <math-schedule-calendar-client-filters></math-schedule-calendar-client-filters>
    </ng-template>

    <ng-template #filtersDrawerTitle>
      <nz-row
        class="schedule-filters-header"
        nzJustify="space-between">
        <nz-col nzSpan="8"
                nzOffset="1">
          <h2>{{'filters' | translate}}</h2>
        </nz-col>
        <nz-col nzSpan="2"
                nzPull="1">
          <button (click)="toggleFiltersDrawer(true)"
                  class="close-schedule-filters-btn">
          </button>
        </nz-col>
      </nz-row>
    </ng-template>

    <ng-template #filtersSidebarTitle>
      <nz-row
        class="schedule-filters-header"
        nzJustify="space-between">
        <nz-col nzSpan="8"
                nzOffset="1">
          <h2>{{'filters' | translate}}</h2>
        </nz-col>

        <nz-col
          nzSpan="2"
          nzPull="1">
          <button (click)="toggleFiltersSidebar(true)"
                  class="close-schedule-filters-btn">
          </button>
        </nz-col>
      </nz-row>
    </ng-template>

    <!-- Lesson details templates -->
    <ng-template #lessonDetailsDrawerTitle>
      <nz-row class="lesson-details-title" nzJustify="center" nzAlign="middle">
        <nz-col nzSpan="2">
          <span nz-icon (click)="onCloseLessonDetails()"><img src="assets/icons/cross.svg" alt="close"></span>
        </nz-col>
        <nz-col nzOffset="2" nzSpan="20">
          <h2>{{'calendar.lesson-details.lesson_details' | translate}}</h2>
        </nz-col>
      </nz-row>
    </ng-template>

    <ng-template #lessonDetailsComponent>
      <mathema-schedule-calendar-lesson-details
        (onEditFirstLesson)="onCloseLessonDetails(true); onOpenFirstLessonForm()"
        (onMoveLesson)="onCloseLessonDetails(true); onOpenMoveLesson()"
        (onSkipLesson)="onOpenSkipLesson($event)"
        (onCancelConductLesson)="onOpenCancelConductLesson()"
        (onConductLesson)="openConductLesson($event)"
        (onCancelLesson)="onOpenCancelLesson()"
        [mobileMode]="mobileMode"
      ></mathema-schedule-calendar-lesson-details>
    </ng-template>

    <ng-template #conductLessonModalComponent>
      <math-conduct-lesson-modal
        [lessonId]="activeLessonToConduct?.id"
        [isConductInsteadSkip]="isConductInsteadSkip"
        (onSuccess)="onLessonConducted()"
        (onCancel)="onCloseConductLesson()"
      ></math-conduct-lesson-modal>
    </ng-template>

    <!-- Create first lesson templates -->
    <ng-template #firstLessonComponent>
      <mathema-schedule-calendar-first-lesson (onCancel)="onCloseFirstLessonForm()"></mathema-schedule-calendar-first-lesson>
    </ng-template>

    <!-- Move lesson component templates -->
    <ng-template #moveLessonComponent>
      <mathema-schedule-calendar-move-lesson
        (onClose)="onCloseMoveLesson()"
        [showNotification]="isSendNotificationNeeded()">
      </mathema-schedule-calendar-move-lesson>
    </ng-template>
  </math-base-wrapper>
</nz-layout>

<!-- Filters modal -->
<nz-modal
  [nzContent]="authService.isClient() ? clientsFiltersComponent : filtersComponent"
  [nzVisible]="authService.isClient() ? !isClientFiltersModalClosed : !isFiltersModalClosed"
  [nzTitle]="'filters' | translate"
  nzClassName="filters-mobile"
  (nzOnCancel)="toggleFiltersModal(true)"
  (nzOnOk)="toggleFiltersModal(true)"
  [nzFooter]="null"
></nz-modal>

<!-- Conduct lesson modal -->
<nz-modal
  [nzContent]="conductLessonModalComponent"
  [nzVisible]="!isConductLessonModalClosed" (nzVisibleChange)="isConductLessonModalClosed = $event"
  nzTitle="{{'calendar.conduct-lesson.title' | translate}}"
  [nzClassName]="mobileMode ? 'calendar-modal-mobile' : 'calendar-modal'"
  (nzOnCancel)="onCloseConductLesson()"
  (nzOnOk)="onCloseConductLesson()"
  [nzFooter]="null"
  nzCentered
  nzWidth="500px"
></nz-modal>

<!-- Lesson details modal -->
<nz-modal
  [nzContent]="lessonDetailsComponent"
  [nzVisible]="!isLessonDetailsModalClosed" (nzVisibleChange)="isLessonDetailsModalClosed = $event"
  nzTitle="{{'calendar.lesson-details.lesson_details' | translate}}"
  nzClassName="lesson-details-mobile"
  (nzOnCancel)="onCloseLessonDetails()"
  (nzOnOk)="onCloseLessonDetails()"
  [nzFooter]="null"
></nz-modal>

<!-- Create first lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="firstLessonComponent"
  [nzVisible]="!isFirstLessonModalClosed"
  (nzVisibleChange)="isFirstLessonModalClosed = $event"
  [nzTitle]="null"
  nzClassName="create-first-lesson-mobile"
  (nzOnCancel)="onCloseFirstLessonForm()"
  (nzOnOk)="onCloseFirstLessonForm()"
  [nzFooter]="null"
></nz-modal>

<!-- Move lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="moveLessonComponent"
  [nzVisible]="!isMoveLessonModalClosed" (nzVisibleChange)="isMoveLessonModalClosed = $event"
  [nzTitle]="null"
  nzClassName="move-lesson-mobile"
  (nzOnCancel)="onCloseMoveLesson()"
  (nzOnOk)="onCloseMoveLesson()"
  [nzFooter]="null"
></nz-modal>

<!-- Vacations modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="vacationsComponent"
  [nzVisible]="!isVacationsModalClosed" (nzVisibleChange)="isVacationsModalClosed = $event"
  [nzTitle]="vacationsModalTitle"
  [nzMaskClosable]="false"
  [nzClassName]="mobileMode ? 'vacations-mobile' : 'vacations-modal'"
  (nzOnCancel)="onCloseVacations()"
  (nzOnOk)="onCloseVacations()"
  [nzFooter]="null"
  nzWidth="400"
  nzCentered
></nz-modal>


<!-- Vacations component templates -->
<ng-template #vacationsComponent>
  <mathema-schedule-calendar-vacations (onSuccess)="onCloseVacations(); scheduleCalendarService.refreshCalendarDate()"></mathema-schedule-calendar-vacations>
</ng-template>

<ng-template #vacationsModalTitle>
  <nz-row class="vacations-title" nzJustify="center" nzAlign="middle">
    <nz-col nzSpan="2">
      <span nz-icon (click)="onCloseVacations()"><img src="assets/icons/cross.svg" alt="close"></span>
    </nz-col>
    <nz-col nzSpan="22">
      <h2>{{'calendar.vacations.header' | translate}}</h2>
    </nz-col>
  </nz-row>
</ng-template>
