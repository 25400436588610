<div class="content">
  <div class='sidebar-content'>
    {{currentUrl}}
    <div class='links'>
      <a
        class="button"
        routerLinkActive="selected"
        [routerLink]="'/' + routes.CALENDAR">
        {{'main.navigation.calendar' | translate}}
      </a>

      @if (authService.isManager() || authService.isAdmin()) {
        <a class="button"
           routerLinkActive="selected"
           [routerLink]="'/' + routes.FREE_HOURS">
          {{'main.navigation.free-hours-search' | translate}}
        </a>
      }

      @if (authService.isManager() || authService.isAdmin()) {
        <a routerLinkActive="selected"
           class='button'
           [routerLink]="'/' + routes.USERS">
          {{'users.menu-title' | translate}}
        </a>
      }

      @if (!authService.isClient()) {
        <a routerLinkActive="selected"
           class='button'
           [routerLink]="['/', routes.STUDENTS]">
          @if (!authService.isTeacher()) {
            <span>
              {{'main.navigation.students' | translate}}
            </span>
          } @else {
            {{'main.navigation.my-students' | translate}}
          }
        </a>
      }

      @if (authService.isTeacher()) {
        <a routerLinkActive="selected"
           class='button'
           [routerLink]="'/' + routes.VIEW_USER_INFO">
          {{'my_cabinet' | translate}}
        </a>
      }

      @if (authService.isClient()) {
        <a routerLinkActive="selected"
           class='button'
           [routerLink]="'/' + routes.CLIENT_PROFILE">
          {{'main.navigation.client-profile' | translate}}
        </a>
      }

      @if (authService.isTeacher() && authService.isUserUkrainian()) {
        <a class='button'
           target="_blank"
           href="https://bit.ly/4f38bqd">
          {{'main.navigation.study-notes' | translate}}
        </a>
      }

      @if (authService.isTeacher() && authService.isUserPoland()) {
        <a class='button'
           target="_blank"
           href="https://bit.ly/4dmcgnz">{{'main.navigation.study-notes' | translate}}</a>
      }

      @if (authService.isTeacher() && authService.isUserUkrainian()) {
        <a class="button"
           target="_blank"
           href="https://mathema.crunch.help/uk/zaghal-ni-ta-orghanizatsiini-pitannia/vzaiemodiia-vchitielia-z-inshimi-priedstavnikami-mathema"
        >
          {{'main.navigation.ua-teacher-referral-message' | translate}}
        </a>
      }

      @if (authService.isTeacherCandidate() && authService.isUserUkrainian()) {
        <a class="button"
           target="_blank"
           href="https://docs.google.com/forms/d/e/1FAIpQLSf246NRisNRXrTWFcTCMF401k5tpRYCFQgtTFlDH9_kYM-viw/viewform"
        >
          {{'main.navigation.ua-teacher-candidate-test-message' | translate}}
        </a>
      }

      @if (authService.isClient() && (authService.isUserUkrainian() || authService.isUserPoland())) {
        <a class="button"
           target="_blank"
           href="https://www.trustpilot.com/review/mathema.me">
         {{'main.navigation.leave-review' | translate}}
        </a>
      }

      <a
        routerLinkActive="selected"
        class='button'
        [routerLink]="['/', routes.NOTIFICATIONS]">
        {{'main.navigation.notifications' | translate}}
      </a>
    </div>
  </div>

  <div class="profile-log-out">
    <div class="user-info-container"
         [class.is-teacher]="authService.isTeacher()"
         (click)="authService.isTeacher() ? redirectToMyAccount():''">
      <div class="user-logo">
        @if (authService.user.avatarLink) {
          <img [src]="authService.user.avatarLink" alt="">
        } @else {
          @if (authService.isFemale() || authService.isMale()) {
            <img [src]="authService.isFemale() ? 'assets/NoPhotoGirl.png' : 'assets/NoPhotoBoy.png'"
                 alt="">
          } @else {
            <span>
              {{ authService.user?.firstName?.[0] | uppercase }}
            </span>
          }
        }
      </div>

      <div class="user-info">
        <span class="name">
          {{ authService.user.firstName }} {{ authService.user.lastName }}
        </span>

        <span class="role">
          @switch (true) {
            @case (authService.isManager()) {
              {{'main.roles.manager' | translate}}
            }

            @case (authService.isAdmin()) {
              {{'main.roles.admin' | translate}}
            }

            @case (authService.isTeacher()) {
              {{'main.roles.teacher' | translate}}
            }

            @default {
              {{'main.roles.client' | translate}}
            }

          }
        </span>
      </div>
    </div>

    <div class="log-out-container">
      <button class="log-out"
              (click)='onLogoutClick()'>
        {{'log_out' | translate}}
      </button>
    </div>
  </div>
</div>


