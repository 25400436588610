import { NotificationType } from '@shared/models/notification.model';

export const notifTypeToIconMap: Record<NotificationType, string> = {
  [NotificationType.MOVE_LESSON]: 'assets/notification-statuses/move-lesson.svg',
  [NotificationType.SKIP_LESSON]: 'assets/notification-statuses/skip-lesson.svg',
  [NotificationType.PROFILE_CHANGE]: 'assets/notification-statuses/profile-change.svg',
  [NotificationType.CONDUCT_INSTEAD_SKIP]: 'assets/notification-statuses/conduct-instead-skip.svg',
  [NotificationType.CREATED_REPORT]: 'assets/notification-statuses/created-report.svg',
  [NotificationType.NEED_REPORT]: 'assets/notification-statuses/need-report.svg',
  [NotificationType.SCHEDULE_CHANGED]: 'assets/notification-statuses/schedule-changed.svg',
  [NotificationType.FIRST_LESSON]: 'assets/notification-statuses/first-lesson.svg',
  [NotificationType.SYSTEM_NOTIFICATION]: 'assets/notification-statuses/system.svg',
}
