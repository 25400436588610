import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BasePagedModel } from '@shared/models/base-paged.model';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseHttpService<T> {
  protected http = inject(HttpClient);
  protected baseURL: string = environment.PifagorApiAddress;
  protected url: string;

  constructor(controller: string) {
    this.url = `${this.baseURL}/${controller}`;
  }

  protected get(address: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${this.url}/${address}`, { params });
  }

  protected getPagedList(address: string = '', params?: HttpParams): Observable<BasePagedModel<T>> {
    return this.http.get<BasePagedModel<T>>(`${this.url}/${address}`, { params });
  }

  protected post<B>(address: string = '', body: B, params?: HttpParams): Observable<void> {
    return this.http.post<void>(`${this.url}/${address}`, { ...body }, { params });
  }

  protected put<B>(address: string = '', body: B, options?: Record<string, unknown>): Observable<T> {
    return this.http.put<T>(`${this.url}/${address}`, body, options);
  }

  protected patch<B>(address: string = '', body: B, params: HttpParams): Observable<void> {
    return this.http.patch<void>(`${this.url}/${address}`, { ...body });
  }

  protected delete(address: string = '', params: HttpParams): Observable<void> {
    return this.http.delete<void>(`${this.url}/${address}`);
  }
}
