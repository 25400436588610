import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NzCollapseComponent,
  NzCollapsePanelComponent,
} from 'ng-zorro-antd/collapse';
import { NzFormDirective } from 'ng-zorro-antd/form';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { TranslatePipe } from '@ngx-translate/core';
import { ConductLessonFormService } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import { needAttentionData } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/const/conduct-lesson-rates-list.const';

@Component({
  selector: 'math-need-attention',
  standalone: true,
  imports: [
    FormsModule,
    NzCollapseComponent,
    NzCollapsePanelComponent,
    NzFormDirective,
    NzIconDirective,
    NzTagComponent,
    TranslatePipe,
    ReactiveFormsModule,
  ],
  templateUrl: './need-attention.component.html',
  styleUrl: './need-attention.component.scss',
})
export class NeedAttentionComponent implements OnInit {
  protected readonly conductLessonFormService = inject(
    ConductLessonFormService
  );

  protected readonly needAttention: Record<string, any>[] = JSON.parse(JSON.stringify(needAttentionData));

  public ngOnInit(): void {
    const needAttention= this.conductLessonFormService.lessonForm.get('teacherRates').value || [];
    this.needAttention.forEach(needAttentionNotes => {
      needAttentionNotes.rates.forEach((rate) => {
        if (needAttention.indexOf(rate.key) !== -1) {
          rate.value = true;
        }
      })
    })
  }

  public onRateDetailChange(key: string, value: boolean) {
    const needAttentionControl =
      this.conductLessonFormService.lessonForm.get('teacherRates');
    const needAttention: string[] = needAttentionControl.value || [];

    if (value) {
      needAttentionControl.setValue([...needAttention, key]);
    } else {
      needAttentionControl.setValue(needAttention.filter((el) => el !== key));
    }
  }
}
