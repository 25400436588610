<nz-layout class="wrapper">
  <nz-row class="header"
          [nzJustify]="'end'"
          nzGutter="15">
    <nz-col class="main-menu-btn">
      <mathema-main-menu></mathema-main-menu>
    </nz-col>

    <nz-col style="margin-right: auto">
      @if(isString(pageTitle())) {
        <h1>{{ pageTitle() }}</h1>
      } @else {
        <h1>
          <ng-container *ngTemplateOutlet="$any(pageTitle())"></ng-container>
        </h1>
      }
    </nz-col>

    @if (actionButtons()) {
      <nz-col
        #mobileTriggerRef
        class="mobile-trigger">
        <button
          [class.attention-button]="actionButtonsAttention()"
          nz-button
          (click)="showMobileActions.set(!showMobileActions())">
          <span nz-icon nzType="more" nzTheme="outline"></span>
        </button>
      </nz-col>

      <nz-col
        class="action-buttons"
        (outsideClick)="onOutsideActionButtonsClick($event)"
        [class.show-actions]="showMobileActions()">
        <ng-container
          *ngTemplateOutlet="$any(actionButtons())">
        </ng-container>
      </nz-col>
    }


    <nz-col>
      <math-header-notifications-widget>
      </math-header-notifications-widget>
    </nz-col>
  </nz-row>

  <nz-content>
    <ng-content></ng-content>
  </nz-content>
</nz-layout>
