import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from '@shared/abstractions/base-http.service';
import { BaseSseService } from '@shared/services/base-sse.service';
import { NotificationMessage } from '@shared/models/notification.model';
import { HttpParams } from '@angular/common/http';
import { BasePagedModel } from '@shared/models/base-paged.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsHttpService extends BaseHttpService<NotificationMessage> {
  private sse = inject(BaseSseService<NotificationMessage>);
  private sseConnection;

  constructor() {
    super('notifications');
  }

  public getNotifications(query?: HttpParams): Observable<BasePagedModel<NotificationMessage>> {
    return this.getPagedList('', query)
  }

  public getLatestNotifications(query?: HttpParams): Observable<BasePagedModel<NotificationMessage>> {
    return this.getPagedList('latest', query)
  }

  public readNotifications(ids: string[] = []) {
    return this.put('read', { ids })
  }

  public create(notification: NotificationMessage): Observable<void> {
    return this.post<NotificationMessage>('', notification)
  }

  public sseConnect(): Observable<NotificationMessage> {
    if (!this.sseConnection) {
      this.sseConnection = this.sse.connect(`${this.baseURL}/sse-notifications`);
    }

    return this.sseConnection;
  }
}
