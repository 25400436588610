<div class="container">

  <span nz-icon
        nzType="close"
        (click)="closeDialog()"
        class="close-icon"
  >
  </span>

  <div class="header"><h1>{{ 'statement_title' | translate }}</h1></div>

  <div class="statement-period">
    <div class="period-label">{{ 'statement_period' | translate }}</div>
    <div class="date-picker">
      <nz-range-picker [(ngModel)]="statementPeriod" (ngModelChange)="periodChosen($event)"></nz-range-picker>
    </div>
  </div>
  <div class="loading-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="table-container" *ngIf="!loading">
    <table mat-table [dataSource]="dataSource" class="statement-table">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="statement-header-cell">{{ 'regular_lesson' | translate }}
          /{{ 'first_lesson' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="statement-data-cell">
          <div class="cell-template">
            <div>
                            <span class="lesson-type"
                                  [className]="isFirstLesson(element.type) ? 'lesson-type-first' : 'lesson-type-regular'">{{ getLessonTypeLabel(element.type) }}</span><br />
              <span class="student-name">{{ element.studentName }}</span>
            </div>
            <div class="tags">
              <ng-container *ngFor="let tag of element.tags">
                <span class="tag">{{ tag | translate }}</span>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef class="statement-header-cell">{{ 'lessons_price_rate' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="statement-data-cell aligned">{{ element.rate }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef
            class="statement-header-cell">{{ 'conducted_lessons_amount' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="statement-data-cell aligned">{{ element.lessonsCount }}</td>
      </ng-container>
      <ng-container matColumnDef="sum">
        <th mat-header-cell *matHeaderCellDef class="statement-header-cell">{{ 'lessons_price_sum' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="statement-data-cell aligned">{{ element.sum }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="statement-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="statement-row"></tr>
    </table>
  </div>

  <div class="statement-total">
    <span class="statement-total-label">{{ 'lessons_total_sum' | translate }}:</span>
    <span class="statement-total-amount">{{ total }} {{ 'currancy' | translate }}</span>
  </div>
  <hr class="separator" />
  <div class="statement-info">
    <span>{{ 'statement_info_main' | translate }}</span>
    <span class="info-attention"><br>{{ 'statement_info_attention' | translate }}</span>
    <span><br>{{ 'statement_info_levelprice' | translate }}</span>
    <span><br>{{ 'statement_info_firstlessonprice' | translate }}</span>
  </div>
</div>
