import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import LocalStorageService, { LocalStorageKey } from '@shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BaseSseService<T> {
  private LocalStorageService = inject(LocalStorageService)

  public connect(url: string): Observable<T> {
    return new Observable((observer) => {
      const authToken = this.LocalStorageService.getItem(LocalStorageKey.PIFAGOR_API_AUTH_TOKEN);
      const eventSource = new EventSource(`${url}?token=${authToken}`);

      eventSource.onmessage = (event) => {
        observer.next(JSON.parse(event.data) as T);
      };

      eventSource.onerror = (error) => {
        observer.error(error);
        eventSource.close();
      };

      return () => eventSource.close();
    });
  }
}
