import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import UserDto, { ApiService } from '../../api/services';
import { GenderEnum, UserRole } from '@shared/models';
import { LanguageEnum } from '@shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutesDefinitions } from '@app/app.routes';
import { calendarCachedFiltersKey } from '../schedule/calendar/schedule-calendar.service';
import LocalStorageService, {
  LocalStorageKey,
} from '@shared/services/local-storage.service';
import { TeacherStatus } from '../schedule/dto/teacher.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public user: UserDto = JSON.parse(this.localStorageService.getItem(LocalStorageKey.USER) || '{}');

  constructor(
    private router: Router,
    private apiService: ApiService,
    private readonly translateService: TranslateService,
    private readonly localStorageService: LocalStorageService,
  ) { }

  public get userRole(): UserRole {
    return this.user.role;
  }

  public isManager(): boolean {
    return this.user.role === UserRole.MANAGER;
  }

  public isAdmin(): boolean {
    return this.user.role === UserRole.ADMIN;
  }

  public isTeacher(): boolean {
    return this.user.role === UserRole.TEACHER;
  }

  public isTeacherCandidate(): boolean {
    return this.user.role === UserRole.TEACHER && this.user.teacherStatus === TeacherStatus.CANDIDATE;
  }

  public isClient(): boolean {
    return this.user.role === UserRole.CLIENT;
  }

  public isTeacherOrClient(): boolean {
    return [UserRole.CLIENT, UserRole.TEACHER].includes(this.user.role);
  }

  public isUserUkrainian(): boolean {
    return this.user.language === LanguageEnum.ukrainian;
  }

  public isUserPoland(): boolean {
    return this.user.language === LanguageEnum.polish;
  }

  public isMale(): boolean {
    return this.getUserGender() === GenderEnum.MALE;
  }

  public isFemale(): boolean {
    return this.getUserGender() === GenderEnum.FEMALE;
  }

  private getUserGender(): string {
    return this.user.gender ? this.user.gender.toLowerCase() : GenderEnum.OTHER;
  }

  public updateUserAvatar(avatarLink: string | null): void {
    this.user.avatarLink = avatarLink;
    this.localStorageService.setItem(LocalStorageKey.USER, JSON.stringify(this.user));
  }

  public isLoggedIn(): boolean {
    return !!this.localStorageService.getItem(LocalStorageKey.AUTH_TOKEN);
  }

  public getOldServerToken(): string {
    return this.localStorageService.getItem(LocalStorageKey.AUTH_TOKEN);
  }

  public get getPifagorAPIToken(): string {
    return this.localStorageService.getItem(LocalStorageKey.PIFAGOR_API_AUTH_TOKEN);
  }

  public removeToken(): void {
    this.localStorageService.removeItem(LocalStorageKey.PIFAGOR_API_AUTH_TOKEN);
    localStorage.removeItem(calendarCachedFiltersKey);
    this.localStorageService.removeItem(LocalStorageKey.AUTH_TOKEN);
    this.localStorageService.removeItem(LocalStorageKey.USER);
    this.localStorageService.removeItem(LocalStorageKey.USER_LANGUAGE);
    this.localStorageService.removeItem(LocalStorageKey.CLIENT_CALENDAR_FILTERS);
    this.user = {} as UserDto;
  }

  async login(email: string, password: string/*, callback?: Subject<string>*/) {
    const { token: oldApiToken } = await this.apiService
      .login({ email, password })
      .toPromise();

    this.localStorageService.setItem(LocalStorageKey.AUTH_TOKEN, oldApiToken);

    const { token, ...user } = await this.apiService.pifagorApiLogin(email, password).toPromise();

    this.localStorageService.setItem(LocalStorageKey.USER, JSON.stringify(user));
    this.localStorageService.setItem(LocalStorageKey.USER_LANGUAGE, user.language.toLowerCase());
    this.translateService.use(user.language.toLowerCase());
    this.localStorageService.setItem(LocalStorageKey.PIFAGOR_API_AUTH_TOKEN, 'Bearer ' + token);

    this.user = user;


    const returnRoute = JSON.parse(this.localStorageService.getItem(LocalStorageKey.AUTH_REDIRECT_ROUTE));
    if (returnRoute && returnRoute.url[0]) {
      this.localStorageService.removeItem(LocalStorageKey.AUTH_REDIRECT_ROUTE);
      await this.router.navigate([returnRoute.url], { queryParams: returnRoute.params});
      return;
    }

    if (this.isTeacher()) {
      await this.router.navigate([AppRoutesDefinitions.CALENDAR]);
    } else if (this.isClient()) {
      await this.router.navigate([AppRoutesDefinitions.CLIENT_PROFILE]);
    } else {
      await this.router.navigate(['']);
    }
  }
}
