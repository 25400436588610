import MaterialDto from './material.dto';
import TeacherDto from './teacher.dto';
import StudentDto from '@shared/services/students/dto/student.dto';

export enum ReportType {
  REGULAR = 'regular',
  DIAGNOSTIC = 'diagnostic',
}

export default interface ReportDto {
  id: string;
  createdAt: string;
  type: ReportType;
  teacherId: string;
  studentId: string;
  links: string[] | null;
  knowledge: string | null;
  gaps: string | null;
  conclusion: string | null;
  recommendation: string | null;
  completionPercentage: string | null;
  materials: MaterialDto[];
  teacher: TeacherDto;
  student: StudentDto;
}
