<form nz-form
      [formGroup]="conductLessonFormService.lessonForm"
      nzLayout="vertical">
  <nz-collapse nzExpandIconPosition="end"
               nzAccordion>
    @for (panel of goodNotes; track panel.group; let index = $index) {
      <nz-collapse-panel
        [nzActive]="index === 0"
        [nzHeader]="panel.group | translate"
      >
        @for (rateItem of panel.rates; track rateItem) {
          <nz-tag
            nzMode="checkable"
            [nzChecked]="rateItem.value"
            (nzCheckedChange)="onRateDetailChange(rateItem.key, $event)"
            >
            <span nz-icon
              nzType="like"
              nzTheme="fill"></span>&nbsp;

              {{ ( rateItem.label ) | translate }}
          </nz-tag>
        }
      </nz-collapse-panel>
    }
  </nz-collapse>
</form>
