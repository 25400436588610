import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { LessonHomeworkCompletion } from '@modules/components/schedule/dto/lesson.dto';

export interface ConductedLessonCommentData {
  lessonSubject: string;
  lessonId: string;
  goodNotes: string[];
  needAttention: string[];
  homeworkCompletion: LessonHomeworkCompletion
}

export interface GPTMessageResponse {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class GptService {
  protected http = inject(HttpClient);
  protected baseURL: string = environment.PifagorApiAddress;
  protected url: string = `${this.baseURL}/integrations/gpt`;

  public createConductedLessonComment(data: ConductedLessonCommentData): Observable<GPTMessageResponse> {
    return this.http.post<GPTMessageResponse>(`${this.url}/conducted-lesson-comment`, { ...data })
  }

  public askQuestion(message: string): Observable<any> {
    return this.http.post(`${this.url}`, { message })
  }
}
