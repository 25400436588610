import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MathemaInfoBlockComponent } from './components/mathema-info-block/mathema-info-block.component';
import { MainMenuComponent } from '@modules/components/main-menu/main-menu.component';
import { MainMenuNavComponent } from '@modules/components/main-menu/main-menu-nav/main-menu-nav.component';
import { NzModule } from '@modules/nz-module';
import { SomeLoadingErrorComponent } from './components/some-loading-error/some-loading-error.component';
import { FilterPipe } from './pipes/filter-pipe';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MathLessonRateComponent } from './components/math-lesson-rate/math-lesson-rate.component';
import { CustomDateFormatPipe } from './pipes/custom-date-format.pipe';
import { BaseWrapperComponent } from './components/base-wrapper/base-wrapper.component';
import { HeaderNotificationsWidgetComponent } from '@shared/components/header-notifications-widget/header-notifications-widget.component';
import { OutsideClickDirective } from '@shared/directives';
import {
  StudentPreferredScheduleComponent
} from '@shared/components/student-preferred-schedule/student-preferred-schedule.component';

@NgModule({
  imports: [
    CommonModule,
    NzModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HeaderNotificationsWidgetComponent,
  ],
  declarations: [
    MathemaInfoBlockComponent,
    MainMenuComponent,
    MainMenuNavComponent,
    SomeLoadingErrorComponent,
    FilterPipe,
    ChangePasswordModalComponent,
    MathLessonRateComponent,
    CustomDateFormatPipe,
    BaseWrapperComponent,
    OutsideClickDirective,
    StudentPreferredScheduleComponent,
  ],
  exports: [
    OutsideClickDirective,
    MathemaInfoBlockComponent,
    MainMenuComponent,
    MainMenuNavComponent,
    SomeLoadingErrorComponent,
    FilterPipe,
    MathLessonRateComponent,
    CustomDateFormatPipe,
    BaseWrapperComponent,
    StudentPreferredScheduleComponent,
  ],
})
export default class SharedModule {}
