<nz-layout class="base">
  <nz-layout class="base-container">
    <nz-row nzJustify="space-between">
      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <div class="logo">
          <img src="assets/mathema-logo.png" alt="Logo">
        </div>
      </nz-col>
      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <app-language-switch></app-language-switch>
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col [nzXs]="24"
              [nzSm]="20"
              [nzMd]="20"
              [nzLg]="20">
        <div class="header mobile-align-center">
          <p class="head-line">
            {{ 'registration' | translate }}
          </p>

          <p class="annotation">
            {{ 'signup_welcome' | translate }}
          </p>
        </div>
      </nz-col>
    </nz-row>
    <nz-row nzJustify="space-evenly">
      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 14, order: 2}"
              [nzMd]="{span: 14, order: 2}"
              [nzLg]="{span: 14, order: 2}"
              class="align-box">
        <img class="illustration"
             alt=""
             src="assets/login/signup.svg">
      </nz-col>

      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 9, order: 1}"
              [nzMd]="{span: 9, order: 1}"
              [nzLg]="{span: 9, order: 1}">
        <div class="form-block">
          <form [formGroup]="user">
            <div class="input-group">
              <span class="input-label">
                {{ 'name' | translate }}
              </span>

              <input nz-input
                     class="input"
                     type="text"
                     name="firstName"
                     formControlName="firstName">

              <span class="input-label">
                {{ 'surname' | translate }}
              </span>

              <input nz-input
                     class="input"
                     type="text"
                     name="lastname"
                     formControlName="lastName">
            </div>

            <div class="input-group">
              <span class="input-label">
                {{ 'email' | translate }}
              </span>

              <nz-input-group [nzPrefix]="usernamePrefix">
                <input nz-input
                       class="input"
                       type="text"
                       name="username"
                       formControlName="email">
              </nz-input-group>
            </div>

            <ng-template #usernamePrefix>
              <span nz-icon>
                <img src="assets/login/username-prefix.svg"
                     alt=""/>
              </span>
            </ng-template>

            <div class="input-group">
              <span class="input-label">
                {{ 'password' | translate }}
              </span>

              <nz-input-group [nzPrefix]="passwordPrefix"
                              [nzSuffix]="passwordSuffix">
                <input nz-input
                       class="input"
                       [type]="getPassViewType()"
                       name="password"
                       formControlName="password">
              </nz-input-group>

              <span class="input-label">
                {{ 'password_confirm' | translate }}
              </span>

              <nz-input-group [nzPrefix]="passwordPrefix"
                              [nzSuffix]="passwordSuffix">
                <input nz-input
                       class="input"
                       [type]="getPassViewType()"
                       name="passwordrepeat"
                       formControlName="passwordConfirm">
              </nz-input-group>

              <div *ngIf="isSubmitted
                        && user.controls['password'].invalid
                        && user.controls['password'].touched"
                   class="alert">
                <span *ngIf="user.controls['password'].errors?.required">
                  {{ 'can_not_be_empty' | translate }}
                </span>

                <span *ngIf="user.controls['password'].errors?.minlength">
                  {{ 'wrong_pass_length' | translate }}
                </span>
              </div>
            </div>

            <ng-template #passwordPrefix>
              <span nz-icon>
                <img src="assets/login/password-prefix.svg"
                     alt="">
              </span>
            </ng-template>

            <ng-template #passwordSuffix>
              <span nz-icon
                    (click)="togglePassView()"
                    class="pointer">
                <img src="assets/login/password-suffix.svg"
                     alt="">
              </span>
            </ng-template>
            <!-- [class.input-error]="touched.password && error.password" [(ngModel)]="password"
        (focus)="focus($event)" (blur)="blur($event)" (keydown)="keyDown($event)"  -->

            <div class="button">
              <!-- <p *ngIf="!!error.wrong" class="error">{{ error.wrong }}</p> -->
              <button nz-button
                      (click)="register()">
                {{ 'signup' | translate }}
              </button>
            </div>
          </form>
        </div>

        <div class="links">
          <div class="login">
            {{ 'have_account' | translate }}
            <a class="login-link"
               routerLink="/login">
              {{ 'signin' | translate }}
            </a>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </nz-layout>
</nz-layout>
