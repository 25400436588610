<button nz-button
        nz-dropdown
        [nzDropdownMenu]="menu"
        [nzOverlayStyle]="dropdownStyle()"
        [nzPlacement]="'bottomRight'"
        nzTrigger="click"
        (nzVisibleChange)="onDropdownVisibleChange($event)"

        nzType="default"
        class="notification-button"
        [ngClass]="{ 'missed-notification': notificationDataService.isNewNotice() }"
        >
  <span nz-icon
        nzType="bell"
        nzTheme="fill"
  ></span>
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="notification-menu">
    <div class="header">
      {{ 'notifications.menu.messages' | translate }}

      <span nz-icon
            (click)="dropdown().descendantMenuItemClick$.next('')"
            nzType="close"></span>
    </div>

    <div class="content">
      <ul>
        @for (message of notificationsList(); track message.notificationId; let last = $last) {
          <math-header-notification-item [message]="message"
                                         [last]="last"
                                         (clicked)="getLastNotifications()"></math-header-notification-item>
        } @empty {
          <li>
            {{ 'notifications.empty' | translate }}
          </li>
        }
      </ul>

      <div class="open-notifications" (click)="goToNotifications()">
        <span nz-icon nzType="unordered-list"></span>&nbsp;

        {{'notifications.all' | translate}}
      </div>
    </div>
  </div>
</nz-dropdown-menu>
