import {
  Component,
  ElementRef,
  inject,
  input,
  InputSignal,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AuthService } from '@modules/components/auth/auth.service';

@Component({
  selector: 'math-base-wrapper',
  templateUrl: './base-wrapper.component.html',
  styleUrls: ['./base-wrapper.component.scss'],
})
export class BaseWrapperComponent {
  public pageTitle: InputSignal<string | TemplateRef<any>> = input.required();
  public actionButtons: InputSignal<TemplateRef<any>> = input();
  public actionButtonsAttention: InputSignal<boolean> = input();

  protected readonly authService = inject(AuthService);

  protected readonly showMobileActions = signal(false);

  @ViewChild('mobileTriggerRef') mobileTriggerRef: ElementRef;

  protected isString(value: unknown): boolean {
    return typeof value === 'string';
  }

  protected onOutsideActionButtonsClick($event: MouseEvent): void {
    if(!this.mobileTriggerRef.nativeElement.contains($event.target)) {
      this.showMobileActions.set(false)
    }
  }
}
