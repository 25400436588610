import { Component, HostListener, inject, input, output } from '@angular/core';
import { notifTypeToIconMap } from '@shared/components/header-notifications-widget/notif-type-to-icon.map';
import { DatePipe, NgClass } from '@angular/common';
import { NotificationMessage } from '@shared/models/notification.model';
import { NotificationsHttpService } from '@shared/services/notifications-http.service';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'math-header-notification-item',
  standalone: true,
  imports: [DatePipe, NgClass],
  templateUrl: './header-notification-item.component.html',
  styleUrl: './header-notification-item.component.scss',
})
export class HeaderNotificationItemComponent {
  public last = input<boolean>();
  public message = input.required<NotificationMessage>();

  public clicked = output<void>();

  private readonly notificationService = inject(NotificationsHttpService);

  @HostListener('click')
  public notificationClicked() {
    if (this.message().readAt) return;

    this.notificationService.readNotifications([this.message().notificationId])
      .pipe(
        take(1),
        finalize(() => this.clicked.emit())
        )
      .subscribe();
  }

  protected readonly notifTypeToIconMap = notifTypeToIconMap;

}
