import { LessonTeacherRates } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/enums/lesson-teacher-rates.enum';

const conDuctLesson = 'calendar.conduct-lesson';
const goodNoteKey = `${conDuctLesson}.good-note-rates`;
const performance = `${goodNoteKey}.performance`;
const activity = `${goodNoteKey}.activity`;
const lessonOrganized = `${goodNoteKey}.lesson-organized`;

export interface TeacherRates {
  group: string;
  rates: {
    key: LessonTeacherRates;
    label: string;
    value?: boolean;
  }[];
}

export const goodNotesDataMap = {
  [LessonTeacherRates.FAST_TO_LEARN]: `${performance}.fast-to-learn`,
  [LessonTeacherRates.NO_ISSUES]: `${performance}.no-issues`,
  [LessonTeacherRates.CORRECT_APPLIED_RULES]: `${performance}.correct-apply-rules`,
  [LessonTeacherRates.SELF_RESOLVED_TASK]: `${performance}.self-resolved-hard-task`,
  [LessonTeacherRates.GOOD_EXPLAINED_SOLUTIONS]: `${performance}.good-explained-solutions`,
  [LessonTeacherRates.FAST_ANSWERS]: `${activity}.fast-answers`,
  [LessonTeacherRates.RESOLVE_EVERYTHING]: `${activity}.resolve-everything`,
  [LessonTeacherRates.GOOD_ATTENTION]: `${activity}.good-attention`,
  [LessonTeacherRates.NOTED_IMPORTANT_INFO]: `${activity}.note-important-info`,
  [LessonTeacherRates.QUICKLY_STARTED_WORK]: `${activity}.quickly-started-work`,
  [LessonTeacherRates.PREPARED]: `${lessonOrganized}.prepared`,
  [LessonTeacherRates.KEPT_ATTENTION]: `${lessonOrganized}.kept-attention`,
  [LessonTeacherRates.RESOLVED_TASKS_IN_TIME]: `${lessonOrganized}.tasks-in-time`,
  [LessonTeacherRates.APPLIED_RECOMMENDATIONS]: `${lessonOrganized}.applied-recommendations`,
  [LessonTeacherRates.EFFICIENCY]: `${lessonOrganized}.efficiency`,
};

export const goodNotesData: TeacherRates[] = [
  {
    group: `${performance}.title`,
    rates: [
      {
        key: LessonTeacherRates.FAST_TO_LEARN,
        label: goodNotesDataMap[LessonTeacherRates.FAST_TO_LEARN],
      },
      {
        key: LessonTeacherRates.NO_ISSUES,
        label: goodNotesDataMap[LessonTeacherRates.NO_ISSUES],
      },
      {
        key: LessonTeacherRates.CORRECT_APPLIED_RULES,
        label: goodNotesDataMap[LessonTeacherRates.CORRECT_APPLIED_RULES],
      },
      {
        key: LessonTeacherRates.SELF_RESOLVED_TASK,
        label: goodNotesDataMap[LessonTeacherRates.SELF_RESOLVED_TASK],
      },
      {
        key: LessonTeacherRates.GOOD_EXPLAINED_SOLUTIONS,
        label: goodNotesDataMap[LessonTeacherRates.GOOD_EXPLAINED_SOLUTIONS],
      },
    ],
  },
  {
    group: `${activity}.title`,
    rates: [
      {
        key: LessonTeacherRates.FAST_ANSWERS,
        label: goodNotesDataMap[LessonTeacherRates.FAST_ANSWERS],
      },
      {
        key: LessonTeacherRates.RESOLVE_EVERYTHING,
        label: goodNotesDataMap[LessonTeacherRates.RESOLVE_EVERYTHING],
      },
      {
        key: LessonTeacherRates.GOOD_ATTENTION,
        label: goodNotesDataMap[LessonTeacherRates.GOOD_ATTENTION],
      },
      {
        key: LessonTeacherRates.NOTED_IMPORTANT_INFO,
        label: goodNotesDataMap[LessonTeacherRates.NOTED_IMPORTANT_INFO],
      },
      {
        key: LessonTeacherRates.QUICKLY_STARTED_WORK,
        label: goodNotesDataMap[LessonTeacherRates.QUICKLY_STARTED_WORK],
      },
    ],
  },
  {
    group: `${lessonOrganized}.title`,
    rates: [
      {
        key: LessonTeacherRates.PREPARED,
        label: goodNotesDataMap[LessonTeacherRates.PREPARED],
      },
      {
        key: LessonTeacherRates.KEPT_ATTENTION,
        label: goodNotesDataMap[LessonTeacherRates.KEPT_ATTENTION],
      },
      {
        key: LessonTeacherRates.RESOLVED_TASKS_IN_TIME,
        label: goodNotesDataMap[LessonTeacherRates.RESOLVED_TASKS_IN_TIME],
      },
      {
        key: LessonTeacherRates.APPLIED_RECOMMENDATIONS,
        label: goodNotesDataMap[LessonTeacherRates.APPLIED_RECOMMENDATIONS],
      },
      {
        key: LessonTeacherRates.EFFICIENCY,
        label: goodNotesDataMap[LessonTeacherRates.EFFICIENCY],
      },
    ],
  },
];

const needAttention = `${conDuctLesson}.need-attention-rates`;
const learnDifficulties = `${needAttention}.learn-difficulties`;
const passivity = `${needAttention}.passivity`;
const selfOrganization = `${needAttention}.self-organization`;

export const needAttentionDataMap = {
  [LessonTeacherRates.NEW_THEME_PROBLEM]: `${learnDifficulties}.new-theme`,
  [LessonTeacherRates.BASE_RULES_ISSUES]: `${learnDifficulties}.base-rules-issues`,
  [LessonTeacherRates.SELF_TASK_FAILED]: `${learnDifficulties}.self-task-failed`,
  [LessonTeacherRates.CONFUSED_ABOUT_CONCEPTS]: `${learnDifficulties}.confused-about-concepts`,
  [LessonTeacherRates.ADDITIONAL_EXPLANATION_NEEDED]: `${learnDifficulties}.additional-explanation-needed`,
  [LessonTeacherRates.LAZY_STARTED_WORK]: `${passivity}.lazy-started-work`,
  [LessonTeacherRates.ONLY_EASY_QUESTIONS_SOLVED]: `${passivity}.only-easy-questions-solved`,
  [LessonTeacherRates.AVOIDED_TASKS]: `${passivity}.avoided-tasks`,
  [LessonTeacherRates.SLOW_TASKS_SOLUTION]: `${passivity}.slow-tasks-solution`,
  [LessonTeacherRates.NOT_COMPLETE_TASKS]: `${passivity}.not-complete-tasks`,
  [LessonTeacherRates.WAS_NOT_PREPARED]: `${selfOrganization}.was-not-prepared`,
  [LessonTeacherRates.DISTRACTED_FROM_WORK]: `${selfOrganization}.distracted-from-work`,
  [LessonTeacherRates.TECHNICAL_ISSUES]: `${selfOrganization}.technical-issues`,
  [LessonTeacherRates.LONG_PREPARATIONS]: `${selfOrganization}.long-preparations`,
  [LessonTeacherRates.IGNORED_COMMENTS]: `${selfOrganization}.ignored-comments`,
};

export const needAttentionData: TeacherRates[] = [
  {
    group: `${learnDifficulties}.title`,
    rates: [
      {
        key: LessonTeacherRates.NEW_THEME_PROBLEM,
        label: needAttentionDataMap[LessonTeacherRates.NEW_THEME_PROBLEM],
      },
      {
        key: LessonTeacherRates.BASE_RULES_ISSUES,
        label: needAttentionDataMap[LessonTeacherRates.BASE_RULES_ISSUES],
      },
      {
        key: LessonTeacherRates.SELF_TASK_FAILED,
        label: needAttentionDataMap[LessonTeacherRates.SELF_TASK_FAILED],
      },
      {
        key: LessonTeacherRates.CONFUSED_ABOUT_CONCEPTS,
        label: needAttentionDataMap[LessonTeacherRates.CONFUSED_ABOUT_CONCEPTS],
      },
      {
        key: LessonTeacherRates.ADDITIONAL_EXPLANATION_NEEDED,
        label: needAttentionDataMap[LessonTeacherRates.ADDITIONAL_EXPLANATION_NEEDED],
      },
    ],
  },
  {
    group: `${passivity}.title`,
    rates: [
      {
        key: LessonTeacherRates.LAZY_STARTED_WORK,
        label: needAttentionDataMap[LessonTeacherRates.LAZY_STARTED_WORK],
      },
      {
        key: LessonTeacherRates.ONLY_EASY_QUESTIONS_SOLVED,
        label: needAttentionDataMap[LessonTeacherRates.ONLY_EASY_QUESTIONS_SOLVED],
      },
      {
        key: LessonTeacherRates.AVOIDED_TASKS,
        label: needAttentionDataMap[LessonTeacherRates.AVOIDED_TASKS],
      },
      {
        key: LessonTeacherRates.SLOW_TASKS_SOLUTION,
        label: needAttentionDataMap[LessonTeacherRates.SLOW_TASKS_SOLUTION],
      },
      {
        key: LessonTeacherRates.NOT_COMPLETE_TASKS,
        label: needAttentionDataMap[LessonTeacherRates.NOT_COMPLETE_TASKS],
      },
    ],
  },
  {
    group: `${selfOrganization}.title`,
    rates: [
      {
        key: LessonTeacherRates.WAS_NOT_PREPARED,
        label: needAttentionDataMap[LessonTeacherRates.WAS_NOT_PREPARED],
      },
      {
        key: LessonTeacherRates.DISTRACTED_FROM_WORK,
        label: needAttentionDataMap[LessonTeacherRates.DISTRACTED_FROM_WORK],
      },
      {
        key: LessonTeacherRates.TECHNICAL_ISSUES,
        label: needAttentionDataMap[LessonTeacherRates.TECHNICAL_ISSUES],
      },
      {
        key: LessonTeacherRates.LONG_PREPARATIONS,
        label: needAttentionDataMap[LessonTeacherRates.LONG_PREPARATIONS],
      },
      {
        key: LessonTeacherRates.IGNORED_COMMENTS,
        label: needAttentionDataMap[LessonTeacherRates.IGNORED_COMMENTS],
      },
    ],
  },
];
