export enum InvoiceCurrency {
  PLN = '33df3867-0c45-47e4-bfc0-65d66593e8a0',
  UAH = 'c1057119-53e6-df11-971b-001d60e938c6',
  USD = '915e8a55-98d6-df11-9b2a-001d60e938c6',
  EUR = 'c0057119-53e6-df11-971b-001d60e938c6',
}

export enum InvoiceMarket {
  ALL = 'ee8bf257-4d93-46e2-ac47-1f3eda524894',
  UKRAINE = 'f5e793ca-f76b-4248-88bf-52b314ef7b2c',
  POLAND = 'b9c17e6a-58ba-48ba-aec2-7fd836751921',
  GREAT_BRITAIN = '422582aa-8f98-4ea2-a9fe-6c1f7b6805ed',
}

export enum InvoicePaymentStatus {
  DRAFT = '02794bf5-52e6-df11-971b-001d60e938c6', // logic equivalent to WAIT_PAYMENT
  WAIT_PAYMENT = '36319d13-98e6-df11-971b-001d60e938c6',
  CANCELED = '3fb932ea-f36b-1410-2691-00155d043205',
  PAID = '698d39fd-52e6-df11-971b-001d60e938c6',
  OVERPAID = '2287d18d-8dbe-41e8-b044-cc7d283fd230',
  PART_PAYMENT = '03794bf5-52e6-df11-971b-001d60e938c6'
}

export enum InvoiceProduct {
  FREE_LESSON = '88594b06-02ab-4d13-82ed-04dd0fb1ece9',
  FREE_LESSON_FOR_SUBSCRIPTION = '4436f6ed-cb34-4506-9f34-b6c2840b2b0a',
  FIRST_LESSON = '01f10482-3e50-42f9-a289-aa1eeabb1cbb',
  FIRST_LESSON_POLAND = '7d80caab-76f0-4e1b-ba1c-1d31357b685f',
  SUBSCRIPTION = '7646feca-01d0-4e9c-aeed-1e520d7808d0',
  SUBSCRIPTION_POLAND = 'f5b88105-5a47-428e-ac70-e1fc96ecdb37',
  NMT_SUBSCRIPTION = 'a8170a61-3586-40f3-98be-93ca0b4edc7b',
}

export default interface InvoiceDto {
  id: string;
  dealId: string;
  lessonPrice: number;
  number: string | null;
  currencyId: InvoiceCurrency | null;
  marketId: InvoiceMarket | null;
  clientId: string | null;
  billedAmount: number | null;
  numberOfLessons: number | null;
  paymentStatusId: InvoicePaymentStatus | null;
  paymentAmount: number | null;
  paymentDate: string | null;
  productId: InvoiceProduct | null;
}
