<div class="student-preferred-schedule-view">
  @for (scheduleItem of preferredSchedule(); track scheduleItem) {
    @if (!isEmptyDaysHidden() ? true : scheduleItem.schedule?.length) {
      <div class="schedule-item">
        <div class="weekday">{{weekdayToWeekdayName(scheduleItem.weekday)}}</div>
        <div class="slots">
          @for (scheduleItem of scheduleItem.schedule; track scheduleItem) {
            <div class="slot">{{formatTimeSlots(scheduleItem.timeSlots)}}</div>
          }
        </div>
      </div>
    }
  }
</div>
