import ClientDto from './client.dto';
import StudentDto from '@shared/services/students/dto/student.dto';
import TeacherDto from './teacher.dto';
import LessonDto from './lesson.dto';
import InvoiceDto from './invoice.dto';

export enum DealType {
  STUDY_REQUEST = '42ace3db-68b9-4850-9378-0964a65f21dc',
  STUDY_PROGRESS = 'b4f2402b-88fe-426b-a72d-64d3c5caa45a',
  SELL_REPEAT = '9a8afec1-ceb2-415b-875e-1bf5e1ee14e6',
  TEACHER_HIRE = '649282ec-0158-44f9-a762-ed8cff03750b',
}

export enum StudyRequestDealStage {
  NEW_REQUEST = '1b07f3c4-3fac-4dc0-8184-145fe3262523',
  WORK_IN_PROGRESS = '90ba380a-d946-45d9-9b58-aedd011f2f1a',
  BILLED_1ST_LESSON_INVOICE = 'a602bbb7-bd0e-42f8-84bb-84abbe7bf400',
  PAID_1ST_LESSON_INVOICE = '290b96f3-7fc5-416b-8d2a-2239ae42493b',
  WAS_ON_1ST_LESSON = '1d6a9433-e5f4-4a85-a983-09b3eb581ffd',
  BILLED_FINAL_INVOICE = '2b0bbd49-8bf5-4346-96eb-1889980c61c3',
  SUBSCRIPTION_PARTIALLY_PAID = 'c9e335b3-eb04-42b0-8e39-c11ed1116ad9',
  SUBSCRIPTION_PAID = 'c0704381-e309-4e42-a797-6c626cd92c7e',
  STUDY_REQUEST_CANCELED = '61c2f0cd-39b7-430e-9e0f-ef07e83a6336',
}

export enum StudyProgressDealStage {
  STUDY_ONGOING = 'e8cdcd06-3547-40ad-aefe-664f271f6fe0', // this
  SUBSCRIPTION_WORKED_OUT = 'a11fe4c2-daeb-421d-a7e0-3f9c308990c8',
  STUDY_PAUSED = 'c2e98eb0-5efc-44d7-9072-6ac0f013d68b',
}

export enum SellRepeatDealStage {
  SUBSCRIPTION_FINISHED = '2f587f06-423a-4137-b48e-ead946ceffa7',
  BILLED_NEW_INVOICE = '5400fe4c-4e71-4d27-83e2-db988932b983',
  NEW_SUBSCRIPTION_PARTIALLY_PAID = 'ad436a98-c73d-4ff6-90ac-b89559a00b28',
  NEW_SUBSCRIPTION_PAID = 'f7a25ec2-9681-46e4-bd4d-963900d3d414',
  SELL_REPEAT_CANCELED = '813b6fe7-68c6-4840-ab2d-331341ef2595',
}

export enum TeacherHireDealStage {
  NEW_REQUEST = '99fa1047-286e-4050-be76-e8765211bdcb',
  INITIAL_CHECK = '520e04a7-722a-4876-8897-855184a6ff4e',
  BOOKED_INTERVIEW = '87bd766e-92f7-4337-a3c2-08e3b814c54e',
  ONBOARDING = '0d83eff1-82d2-44b1-9701-d504201d23cc',
  INTERNSHIP = 'b69b6541-d9a6-4ef3-9a70-6c967ad5d917',
  WORKING = 'e11e27ab-e4a8-4dec-92ea-67de18da1d5a',
  STOP_WORK_IN_PROCESs = 'e1931207-6b68-42e1-a14c-8aa268a76bef',
  NOT_WORKING = '70987d98-5523-4ee5-b0a9-809faa8d8426',
  REFUSED = '0a4dab8a-1b29-4913-b646-d69c19fcfdee',
}

export type DealStage = StudyRequestDealStage | StudyProgressDealStage | SellRepeatDealStage | TeacherHireDealStage;

export enum DealInternalType {
  REPORT = 'report',
  REPORT_AND_VERIFY = 'report-and-verify',
}

export enum DealStudentGoal {
  FILL_GAPS = 'fill-gaps',
  INTEREST_IN_LEARNING = 'interest-in-learning',
  PREPARE_EXAMS = 'prepare-exams',
  RAISE_GENERAL_LEVEL = 'raise-general-level',
  DEEP_LEARNING = 'deep-learning',
  OTHER = 'other',
}

export default interface DealDto {
  id: string;
  dealNumber: number;
  typeId: DealType;
  stageId: DealStage;
  region: string;
  teacherId: string;
  studentId: string;
  clientId: string;
  classNumber: string;
  parentComment: string;
  comment: string;
  lang: string;
  teachingLanguageOther: string;
  paidLessons: number;
  unusedLessons: number;
  invoices: InvoiceDto[];
  lessons: LessonDto[];
  student: StudentDto;
  client: ClientDto;
  teacher: TeacherDto;
  internalType?: DealInternalType | null;
  studentGoal?: DealStudentGoal | null;
  studentGoalOther?: string | null;
  parentDeal?: DealDto;
}
