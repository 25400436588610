import {
  Component,
  computed,
  inject,
  OnInit,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { NotificationsHttpService } from '@shared/services/notifications-http.service';
import { NotificationsDataService } from '@shared/services/notifications-data.service';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from '@shared/components/auto-unsubscribe/auto-unsubscribe.component';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NgClass } from '@angular/common';
import {
  NzDropdownButtonDirective,
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { TranslatePipe } from '@ngx-translate/core';
import { NotificationMessage } from '@shared/models/notification.model';
import { fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutesDefinitions } from '@app/app.routes';
import { HeaderNotificationItemComponent } from '@shared/components/header-notifications-widget/components/header-notification-item/header-notification-item.component';

@Component({
  selector: 'math-header-notifications-widget',
  standalone: true,
  imports: [
    NzButtonComponent,
    NzIconDirective,
    NgClass,
    NzDropdownButtonDirective,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    TranslatePipe,
    HeaderNotificationItemComponent,
  ],
  providers: [NotificationsDataService, NotificationsHttpService],
  templateUrl: './header-notifications-widget.component.html',
  styleUrl: './header-notifications-widget.component.scss',
})
export class HeaderNotificationsWidgetComponent
  extends AutoUnsubscribeComponent
  implements OnInit
{
  private readonly defaultDropdownStyle = {
    width: '425px',
  };
  protected readonly AppRoutesDefinitions = AppRoutesDefinitions;

  private readonly notificationService = inject(NotificationsHttpService);
  protected readonly notificationDataService = inject(NotificationsDataService);
  protected readonly router = inject(Router);

  protected readonly dropdown = viewChild(NzDropdownMenuComponent);

  protected readonly notificationsList: WritableSignal<NotificationMessage[]> =
    this.notificationDataService.get();

  private readonly unreadNotificationIds = computed(() =>
    this.notificationsList()
      .filter(({ readAt }) => !readAt)
      .map(({ notificationId }) => notificationId)
  );
  protected readonly dropdownStyle = signal<Record<string, string>>(
    this.defaultDropdownStyle
  );

  public ngOnInit(): void {
    this.setDropdownStyles(window?.innerWidth);
    this.trackResize();
    this.getLastNotifications();
    this.notificationsSubscriber();
  }

  protected getLastNotifications(): void {
    this.notificationService
      .getLatestNotifications()
      .pipe(take(1))
      .subscribe(({ page }) => {
        this.notificationDataService.init(page);
      });
  }

  private notificationsSubscriber(): void {
    this.notificationService
      .sseConnect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => this.notificationDataService.add(message));
  }

  private trackResize(): void {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
        map((event) => (event.target as Window).innerWidth),
        takeUntil(this.destroy$)
      )
      .subscribe((size) => this.setDropdownStyles(size));
  }

  private setDropdownStyles(width: number): void {
    if (width < 475) {
      this.dropdownStyle.set({
        width: width + 'px',
        'margin-right': '-25px',
      });
    } else {
      this.dropdownStyle.set(this.defaultDropdownStyle);
    }
  }

  protected readAllNotifications(): void {
    if (!this.unreadNotificationIds().length) return;

    this.notificationService
      .readNotifications(this.unreadNotificationIds())
      .pipe(take(1))
      .subscribe(() => this.getLastNotifications());
  }

  protected onDropdownVisibleChange($event: boolean) {
    if (!$event && this.unreadNotificationIds().length) {
      this.readAllNotifications();
    }
  }

  protected goToNotifications(): void {
    if (this.unreadNotificationIds().length) this.readAllNotifications();

    this.router.navigate(['/', AppRoutesDefinitions.NOTIFICATIONS]);
  }
}


