import { StepInterface } from '@shared/components/customized-stepper/step.interface';

export enum ConductLessonStepNames {
  LESSON_INFO = 0,
  GOOD_NOTES = 1,
  NEED_ATTENTION = 2,
  ADDITIONAL_INFO = 3,
}

export const ConductLessonSteps: StepInterface[] = [
  {
    number: ConductLessonStepNames.LESSON_INFO,
    title: 'calendar.conduct-lesson.lesson-info',
  },
  {
    number: ConductLessonStepNames.GOOD_NOTES,
    title: 'calendar.conduct-lesson.good-notes',
  },
  {
    number: ConductLessonStepNames.NEED_ATTENTION,
    title: 'calendar.conduct-lesson.need-attention',
  },
  {
    number: ConductLessonStepNames.ADDITIONAL_INFO,
    title: 'calendar.conduct-lesson.additional-info',
  }
]
