import { Component, input, InputSignal } from '@angular/core';
import { StudentPreferredScheduleItem } from '@shared/services/students/dto/student.dto';
import { DateTime } from 'luxon';
import LanguageService from '@shared/language/language.service';
import {
  timeSlotDiapasonToTimeString
} from '@shared/utils';

@Component({
  selector: 'math-student-preferred-schedule',
  templateUrl: './student-preferred-schedule.component.html',
  styleUrl: './student-preferred-schedule.component.scss'
})
export class StudentPreferredScheduleComponent {
  public isEmptyDaysHidden: InputSignal<boolean> = input();
  public preferredSchedule: InputSignal<StudentPreferredScheduleItem[]> = input();

  constructor(
    private readonly languageService: LanguageService,
  ) {
  }

  public weekdayToWeekdayName(day: number): string {
    const date = DateTime.fromFormat(`${day}`, 'E').toJSDate();
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
    });

    return formatter.format(date);
  }

  public formatTimeSlots(timeSlots: number[]): string {
    return timeSlotDiapasonToTimeString(timeSlots);
  }
}
