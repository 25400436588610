import {
  Component,
  ElementRef,
  HostListener,
  inject,
  input,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import SharedModule from '@shared/shared.module';
import { NgClass, NgStyle } from '@angular/common';
import { ConductLessonFormService } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/services/notification.service';
import {
  ConductedLessonCommentData,
  GptService,
} from '@shared/services/gpt.service';
import {
  goodNotesDataMap,
  needAttentionDataMap,
} from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/const/conduct-lesson-rates-list.const';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from '@shared/components/auto-unsubscribe/auto-unsubscribe.component';
import { NzModule } from '@modules/nz-module';

@Component({
  selector: 'math-additional-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    LoaderComponent,
    NzModule,
    NgStyle,
    NgClass,
  ],
  templateUrl: './additional-info.component.html',
  styleUrl: './additional-info.component.scss',
})
export class AdditionalInfoComponent
  extends AutoUnsubscribeComponent
  implements OnInit
{
  public lessonId = input<string>();

  protected conductLessonFormService = inject(ConductLessonFormService);
  protected notificationService = inject(NotificationService);
  protected translateService = inject(TranslateService);
  protected gptService = inject(GptService);

  protected notesLoader = signal(true);

  private readonly supportedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/webp',
  ];
  private readonly oneKb: number = 1000;

  public linkInputVisible = false;
  public linkInputValue = '';

  protected isFocusedNotes = false;

  @ViewChild('linkInputElement', { static: false })
  linkInputElement?: ElementRef;

  @HostListener('document:keydown.control.v')
  public onPasteByCtrlV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.control.м')
  public onPasteByCtrlVCyrillic() {
    this.handleClickPaste().then();
  }

  @HostListener('document:keydown.meta.v')
  public onPasteByCommandV() {
    this.handleClickPaste().then();
  }

  @HostListener('document:keydown.meta.м')
  public onPasteByCommandVCyrillic() {
    this.handleClickPaste().then();
  }

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.trackNoteChanges();
    this.applyAINote();
  }

  private trackNoteChanges(): void {
    this.conductLessonFormService.lessonForm.controls.notes.valueChanges
      .pipe(
        filter(() => this.isFocusedNotes),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.conductLessonFormService.isHumanNote = true;
        this.conductLessonFormService.isNoteGenerated = false;
      });
  }

  private applyAINote(): void {
    const form = this.conductLessonFormService.lessonForm.value;

    const isChangedRates = this.conductLessonFormService.isChangedRates();
    const isChangedHomeworkCompletion = this.conductLessonFormService.isChangedHomeworkCompletion();
    const isHumanNote = this.conductLessonFormService.isHumanNote();

    const notesIsFilledAndRatesNotChanged =
      form.notes?.length && !isChangedRates && !isChangedHomeworkCompletion;
    const notesWasCreatedByUser = form.notes?.length && isHumanNote;

    if (
      !form.teacherRates ||
      notesIsFilledAndRatesNotChanged ||
      notesWasCreatedByUser
    ) {
      this.notesLoader.set(false);
      this.conductLessonFormService.isDisabledSubmit = false;
      return;
    }

    this.conductLessonFormService.isDisabledSubmit = true;
    this.conductLessonFormService.lessonForm.controls.approveAiComment.patchValue(false);

    const { goodNotes, needAttention } = this.getHumanizedRates(form.teacherRates);

    const data: ConductedLessonCommentData = {
      lessonSubject: form.subject,
      lessonId: this.lessonId(),
      goodNotes,
      needAttention,
      homeworkCompletion: this.conductLessonFormService.lessonForm.value.homeworkCompletion,
    };

    this.gptService
      .createConductedLessonComment(data)
      .pipe(
        tap((resp) =>
          this.conductLessonFormService.isNoteGenerated = !!resp.message.length),
        finalize(() => {
          this.notesLoader.set(false);
          this.conductLessonFormService.isDisabledSubmit = false;
          this.conductLessonFormService.isHumanNote = false;
          this.conductLessonFormService.isChangedRates = false;
          this.conductLessonFormService.isChangedHomeworkCompletion = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(({ message }) => {
        this.conductLessonFormService.lessonForm
          .get('notes')
          .patchValue(message);
      });
  }

  private getHumanizedRates(teacherRates: string[]): Record<string, string[]> {
    return [...teacherRates].reduce(
      (acc, lessonRate) => {
        if (goodNotesDataMap[lessonRate]) {
          acc.goodNotes.push(
            this.translateService.instant(goodNotesDataMap[lessonRate])
          );
        } else {
          acc.needAttention.push(
            this.translateService.instant(needAttentionDataMap[lessonRate])
          );
        }

        return acc;
      },
      { goodNotes: [], needAttention: [] }
    );
  }

  private async handleClickPaste(): Promise<void> {
    const clipboardItems = await navigator.clipboard.read();

    if (!clipboardItems.length) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'calendar.conduct-lesson.upload-err-by-empty-buffer'
        )
      );
      return;
    }

    const [item] = clipboardItems;

    if (item.types.find((type) => type === 'text/plain')) {
      return;
    }

    const foundImageMimeType = item.types.find((type: string) =>
      this.supportedFileTypes.includes(type)
    );

    if (foundImageMimeType) {
      const blob = await item.getType(foundImageMimeType);
      const uid = crypto.randomUUID();
      const file = new File([blob], uid, {
        type: foundImageMimeType,
      });

      (file as any).uid = uid;

      this.beforeImageUpload(file as any);
    } else {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'calendar.conduct-lesson.upload-err-by-type-buffer'
        )
      );
    }
  }

  public beforeImageUpload = (file: NzUploadFile): boolean => {
    if (this.conductLessonFormService.getFiles().length >= 5) {
      return false;
    }

    if (!this.supportedFileTypes.includes(file.type)) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'calendar.conduct-lesson.upload-err-by-type'
        )
      );
      return false;
    }

    if (file.size > this.oneKb * 512) {
      this.notificationService.showInfoMessage(
        this.translateService.instant(
          'calendar.conduct-lesson.upload-err-by-size',
          { size: Math.ceil(file.size / 1000) }
        )
      );
      return false;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      file.url = `${event.target.result}`;
      this.conductLessonFormService.addFile([file]);

      setTimeout(() => {
        this.conductLessonFormService.fileList = [
          ...this.conductLessonFormService.fileList,
        ];
      }, 0);
    };

    reader.readAsDataURL(file as any);

    return false;
  };

  public handleUploadImageChange(change: NzUploadChangeParam): void {
    const { file } = change;

    if (file.status === 'removed' && file.currentlyUploaded) {
      this.conductLessonFormService.filesIdsToRemove.push(file.id);
    }
  }

  public handleImageRemove = (file: NzUploadFile): boolean => {
    this.conductLessonFormService.filesIdsToRemove.push(file.id);
    return true;
  };

  public handleImagePreview = async (file: NzUploadFile): Promise<void> => {
    this.conductLessonFormService.imagePreview = file.url;
    this.conductLessonFormService.isImagePreviewVisible = true;
  };

  public showLinkInput(): void {
    this.linkInputVisible = true;
    setTimeout(() => {
      this.linkInputElement?.nativeElement?.focus();
    }, 10);
  }

  public handleLinkInputConfirm(): void {
    if (this.linkInputValue.length > 255) {
      this.linkInputValue = '';
      this.linkInputVisible = false;
      return;
    }

    if (
      this.linkInputValue &&
      this.conductLessonFormService.links.controls
        .map((el) => el.value)
        .indexOf(this.linkInputValue) === -1
    ) {
      this.conductLessonFormService.links.push(
        new UntypedFormControl(this.linkInputValue)
      );
    }

    this.linkInputValue = '';
    this.linkInputVisible = false;
  }

  public onRemoveLink(index): void {
    this.conductLessonFormService.links.removeAt(index);
  }

  public sliceLinkValue(link: string): string {
    const isLongLink = link.length > 15;
    return isLongLink ? `${link.slice(0, 15)}...` : link;
  }
}
