import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  CLIENT_CALENDAR_FILTERS = 'client_calendar_filters',
  AUTH_REDIRECT_ROUTE = 'returnRoute',
  USER = 'user',
  USER_LANGUAGE = 'userLanguage',
  AUTH_TOKEN = 'authorization-token',
  PIFAGOR_API_AUTH_TOKEN = 'pifagor-api-auth-token',
}

@Injectable({
  providedIn: 'root',
})
export default class LocalStorageService {
  public setItem(key: LocalStorageKey, item: string): void {
    localStorage.setItem(key, item);
  }

  public getItem(key: LocalStorageKey): string {
    return localStorage.getItem(key);
  }

  public removeItem(key: LocalStorageKey): void {
    localStorage.removeItem(key);
  }
}
