<nz-steps [nzCurrent]="activeStep()"
          [nzProgressDot]="progressTemplate">
  @for (step of steps(); track step.number; let i = $index) {
    <nz-step (click)="switchStep(i)"
             [ngClass]="{'active-step': activeStep() === step.number}"
             nzDescription="">
    </nz-step>
  }
</nz-steps>

<ng-template #progressTemplate
             let-dot
             let-status="status"
             let-index="index">
        <span nz-popover
              [nzPopoverContent]="index !== activeStep() ? (steps()[index].title | translate) : ''"
              style="margin-left: -100%;">
          <ng-template [ngTemplateOutlet]="dot"></ng-template>
        </span>
</ng-template>
