<div class="loader">
  <nz-spin [nzIndicator]="indicatorTemplate"
           nzSimple>
  </nz-spin>

  <ng-template #indicatorTemplate>
        <span style="font-size: 36px; color: #613CB0;"
              nz-icon
              nzType="loading">
        </span>
  </ng-template>
</div>
